@import '~@setapp/ui-kit/styles/variables';

.sidebar-nav__collapse {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @media (min-width: $screen-sm-min) {
    height: auto;
    flex: 1;
  }

  @media(max-width: $screen-xs-max) {
    padding-top: 23px;
    padding-bottom: 7px;
    background: $sidebar-nav-bg;
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: -6px;
    border-radius: 0 0 $border-radius-base $border-radius-base;

    &_expanded {
      display: block;
    }
  }
}
