@import '~@setapp/ui-kit/styles/variables';

.price-plan-benefits {
  display: flex;
  flex-wrap: wrap;
  margin: 36px 0 24px;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 400px;
    left: 0;
    right: 0;
    bottom: -24px;
    background: $off-white;
    border-radius: 20px;
  }

  &__item {
    width: calc((100% / 3) - 8px);
    display: flex;

    @media (max-width: $screen-md-max) {
      width: 50%;
    }

    @media (max-width: $screen-xs-max) {
      width: 100%;
    }

    &:nth-child(2), &:nth-child(5) {
      @media (min-width: $screen-md-max) {
        padding: 0 56px;
        width: calc((100% / 3) + 16px);
      }
    }
  }
}
