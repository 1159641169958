.remove-family-member-modal {
  &__avatar {
    max-width: 50px;
    max-height: 50px;
    border-radius: 50%;
  }

  &__title {
    margin-bottom: 0;
  }

  &__subtitle {
    color: #808287;
    font-weight: 500;
  }

  &__description {
    margin: 20px 0 30px;
  }
}
