@import '~@setapp/ui-kit/styles/variables';

.change-plan-step {
  &__price-plans-list {
    list-style: none;
    margin: 12px 0;
    padding: 36px 16px 0 0;
    max-height: 50vh;
    overflow: scroll;
  }
}
