@import '~@setapp/ui-kit/styles/variables';

.account-settings {
  &-section {
    margin-bottom: $line-height-computed * 1.5;

    &__title {
      margin: 0 0 $line-height-computed;
    }

    &__password-field {
      position: relative;
    }
  }

  &-delimiter {
    $section-delimiter: 1px solid $very-light-pink;
    border-bottom: $section-delimiter;
    margin-bottom: $line-height-computed;
  }
}
