@import '~@setapp/ui-kit/styles/variables';

.delete-account-secondary {
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 60px 72px;
    background: #fafafa;
    border-radius: 20px;
  }

  &__close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__image {
    width: 70%;
  }

  &__heart-icon {
    display: inline-block;
    margin-left: 6px;
    vertical-align: middle;
  }
}

.delete-account-primary {
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-bottom: 40px;

    @media (max-width: $screen-md-max) {
      padding-top: 50px;
    }
  }

  &__close-btn {
    position: absolute;
    top: 0;
    right: 0;

    @media (min-width: $screen-lg-min) {
      display: none;
    }
  }
}
