@import '~@setapp/ui-kit/styles/variables';
@import '~@setapp/ui-kit/styles/mixins';

.sidebar-nav__item {
  position: relative;
  display: block;

  &-icon {
    margin: -5px 0 0 6px;
  }

  &-label {
    vertical-align: middle;
    border-radius: $border-radius-large;
    padding: 4px 8px;
    font-size: 10px;
    letter-spacing: normal;
    color: $white;
    background: $sea;

    &_new {
      background: $sea;
    }

    &_beta {
      background: #e6842e;
    }
  }
}

.sidebar-nav__link {
  position: relative;
  display: block;
  margin-bottom: 7px;
  padding: 8px 24px;
  font-size: 18px;
  letter-spacing: 0.9px;
  line-height: 1.43;
  color: $sidebar-nav-color;
  background-color: transparent;
  transition: background-color 0.3s;
  border-radius: $btn-border-radius-base;

  @media (min-width: $screen-sm-min) {
    padding-left: $sidebar-nav-item-padding-horizontal;
    padding-right: $sidebar-nav-item-padding-horizontal;
    letter-spacing: normal;
    font-size: $sidebar-nav-font-size;
  }

  &:hover {
    color: $sidebar-nav-color;
    text-decoration: none;
    background-color: $sidebar-nav-item-hover-bg-color;
  }

  &:focus,
  &_active {
    color: $sidebar-nav-color;
    text-decoration: none;
    background-color: $sidebar-nav-item-active-bg-color;
  }

  &_external {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 20px;
      width: 20px;
      margin-left: 3px;
      background: url('images/arrow.svg') no-repeat center;
    }
  }
}
