@import '~@setapp/ui-kit/styles/variables';

.activate-ios-page {
  padding: 40px 20px 20px;
  max-width: 756px;
  margin: 0 auto;
  overflow-y: auto;
  max-height: 100%;

  &__back-btn {
    margin-left: 10px;
    text-align: left;
  }

  &__step {
    padding: 24px 20px;
    border: solid 2px #d3d3d3;
    border-radius: 16px;
    text-align: center;

    &_inactive {
      padding-bottom: 4px;
      opacity: 0.75;
    }

    &_activation {
      margin-top: 20px;
      border: solid 3px #efefef;
    }
  }

  &__step-number {
    text-transform: uppercase;
    color: #e6a873;
  }

  &__step-header {
    padding: 0 24px;

    &_wider {
      padding: 0;
    }
  }

  &__install-text {
    color: #929295; // TODO: ask designer about color
  }

  &__app-store-btn {
    margin-top: -1px;
    color: #e6842e; // TODO: ask designer about color
  }

  &__icon-container {
    position: relative;
    overflow: hidden;
    width: 166px;
    height: 166px;
    margin: 40px auto 44px;
    padding: 0;
    border-radius: 22px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      display: block;
      height: 100%;
      width: 100%;
      border-radius: 22px;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    }
  }
}
