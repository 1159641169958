@import '~@setapp/ui-kit/styles/variables';

.panel-box {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 5px 20px 0 #d9dada;
  border-radius: 15px;

  &_full-height {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
  }

  &_type-warning {
    border: 5px solid #ffe0e4;
    border-radius: 20px;
    box-shadow: none;
  }
}
