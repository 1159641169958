@import '~@setapp/ui-kit/styles/variables';

.gift-card-type {
  position: relative;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  transform: translateY(0);

  &:hover,
  &:focus {
    cursor: pointer;
    transform: translateY(-5px);
  }

  &__badge {
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
    height: 70px;
    z-index: 10;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 74px 70px 0;
      border-color: transparent #71719a transparent transparent;
      z-index: -1;
    }

    &-text {
      color: $white;
      transform: rotate(45deg);
      position: absolute;
      top: 10px;
      right: 6px;
      font-size: 15px;
      font-weight: $font-weight-bold;
    }
  }

  &__price-row {
    border-bottom: $very-light-pink 1px solid;
    padding-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }

  &__price {
    margin-right: 8px;
    font-size: 36px;
    font-weight: 600;
    line-height: 1.44;
  }

  &__price_crossed {
    color: $heather;
    font-size: 20px;
    text-decoration: line-through;
  }
}
