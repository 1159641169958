@import '~@setapp/ui-kit/styles/variables';
@import '~@setapp/ui-kit/styles/mixins';

.account-info-row {
  display: table;
  width: 100%;
  margin-bottom: $line-height-computed * 0.5;

  &-title {
    display: table-cell;
    width: 33%;
    color: $heather;
  }

  &-value {
    display: table-cell;
    padding-left: 5px;
    font-weight: 500;

    @include word-break;
  }
}
