@import '~@setapp/ui-kit/styles/variables';

.desktop-qr-code {
  &__title {
    font-size: 32px;
    line-height: 36px;
    margin-top: 6px;
    margin-bottom: 20px;
  }

  &__description {
    line-height: 28px;
    font-size: 18px;
  }

  &__container {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    @media (min-width: $screen-md-min) {
      grid-template-columns: 6fr 4fr;
    }
  }

  &__requirements {
    background-color: #f6f2ee;
    border-radius: 16px;
    padding: 40px;
  }

  &__requirements-title {
    font-size: 20px;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: 32px;
  }

  &__list {
    padding: 0;
  }

  &__list-item {
    list-style: none;
    font-size: $font-size-lg;
    padding-left: 28px;
    line-height: 28px;

    &::before {
      background-image: url('../../images/bullet.svg');
      content: '';
      display: inline-block;
      height: 16px;
      width: 16px;
      margin-left: -28px;
      margin-right: 12px;
      position: relative;
      top: 2px;
    }
  }

  &__code {
    border: 1px solid #e8e8e8;
    border-radius: 16px;
    padding: 40px;
  }

  &__code-title {
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    line-height: 36px;
  }

  &__countries {
    font-size: $font-size-xs;
    color: #77767a;
  }
}
