.footer-social {
  list-style-type: none;
  padding: 0;
  font-size: 0;
  margin-bottom: 0;

  &__item {
    display: inline-block;
    margin-left: 10px;
    font-size: 0;

    &:first-child {
      margin-left: 0;
    }
  }

  &__link {
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }
}
