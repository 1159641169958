@import '~@setapp/ui-kit/styles/variables';
@import '~@setapp/ui-kit/styles/mixins';

.sidebar-nav {
  @media (min-width: $screen-sm-min) {
    display: flex;
    flex-flow: column nowrap;
    background: $sidebar-nav-bg;
  }

  &::before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #26262b;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.3s, opacity 0.3s;
    z-index: -1;

    @media (min-width: $screen-sm-min) {
      display: none;
    }
  }

  &_expanded {
    &::before {
      visibility: visible;
      opacity: 0.4;
    }
  }
}
