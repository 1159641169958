@import '~@setapp/ui-kit/styles/variables';
@import '~@setapp/ui-kit/styles/mixins';

.log-out-link {
  &__title {
    color: $dusty-orange;
    font-weight: 500;
  }

  &__icon {
    margin-left: 4px;
  }

  &__email {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    opacity: 0.7;
    text-transform: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    letter-spacing: 0;
    margin-top: 4px;

    @include font-antialiased(true);

    @media (min-width: $screen-sm-min) {
      line-height: $line-height-base;
      margin-top: 5px;
      font-size: 14px;
    }
  }
}
