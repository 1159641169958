/* stylelint-disable selector-max-type, selector-attribute-quotes */

@import '~@setapp/ui-kit/styles/variables';

.collapsible-group {
  /* reset UI Kit Panel Collapsible styles */
  .panel-collapsible {
    margin-bottom: 6px;
    border: none;
  }

  .panel-collapsible > .panel-heading,
  .panel-collapsible > .panel-heading a[role=button],
  .panel-collapsible > .panel-heading a[role=button].collapsed,
  .panel-collapsible .panel-body {
    background-color: unset;
  }

  /* modify UI Kit Panel Collapsible */
  .panel-collapsible:hover {
    background-color: $light-silver;
    border-radius: 6px;
  }

  .panel-collapsible > .panel-heading a[role=button] {
    padding: 6px 20px 4px;
    font-weight: 500;
    line-height: 1.78;
  }

  .panel-collapsible > .panel-heading a[role=button]:focus {
    color: $dark-grey;
  }

  .panel-collapsible .panel-body {
    padding: 0 20px 6px;
  }

  /* modifications */
  &_with-bullet {
    .panel-collapsible > .panel-heading .panel-title {
      display: flex;
      align-items: baseline;
    }

    .panel-collapsible > .panel-heading .panel-title::before {
      content: url('images/bullet-icon.svg');
      position: relative;
      top: 2px;
      padding-left: 20px;
    }

    .panel-collapsible > .panel-heading a[role=button] {
      flex: 1;
      padding-left: 12px;
    }

    .panel-collapsible .panel-body {
      padding-left: 48px;
    }
  }

  &_with-caret {
    .panel-collapsible > .panel-heading a[role=button].collapsed::after {
      content: url('images/arrow-down-icon.svg');
    }

    .panel-collapsible > .panel-heading a[role=button]::after {
      content: url('images/arrow-up-icon.svg');
    }

    .panel-collapsible .panel-body {
      padding-right: 40px;
    }
  }
}
