@import '~@setapp/ui-kit/styles/variables';

.footer {
  padding: 0 0 40px;

  &__container {
    max-width: 1440px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: $screen-md-min) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  &-content {
    border-top: 1px solid #e5e5e5;
    padding-top: 28px;
    font-size: 12px;
    color: $dark-grey;

    &__link {
      color: #969799;
      text-decoration: none;

      &:hover {
        color: #969799;
      }

      &_credits {
        vertical-align: middle;
        margin-bottom: 2px;
        margin-left: 4px;
      }
    }

    &__terms {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      gap: 14px;

      @media (min-width: $screen-sm-min) {
        gap: 28px;
      }
    }
  }

  &__social {
    margin-top: 28px;

    @media (min-width: $screen-sm-min) {
      text-align: right;
      margin-top: 0;
    }
  }
}
