.social-auth-newsletter {
  max-width: 480px;
  margin: auto;

  &__advice-check {
    margin-bottom: 16px;
  }

  &__terms-check {
    margin-bottom: 40px;
  }

  &__error-message {
    margin-top: -8px;
  }
}
