@import '~@setapp/ui-kit/styles/variables';
@import '~@setapp/ui-kit/styles/mixins';

@mixin layout-container {
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.app-layout {
  $z-index-notifications: 1001;
  $z-index-sidebar: 20;
  $z-index-header: 30;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 320px;

  &__header {
    flex: none;
    position: relative;
    z-index: $z-index-header;
    transition: box-shadow 0.3s;

    &_with-shadow {
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.21);
    }
  }

  &__container {
    @include layout-container;
  }

  &__scrollable {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__main {
    @include layout-container;
    padding-top: 20px;
    padding-bottom: 100px;
    display: flex;
    flex-flow: column nowrap;
    flex: 1 0 auto;

    @media (min-width: $screen-sm-min) {
      flex-direction: row;
      padding-top: 40px;
    }
  }

  &__sidebar {
    padding: 0 20px;
    position: relative;
    z-index: $z-index-sidebar;

    @media (min-width: $screen-sm-min) {
      width: 240px;
      flex-shrink: 0;
      flex-grow: 0;
    }

    @media (min-width: $screen-md-min) {
      width: 280px;
      padding: 8px 40px 0;
    }
  }

  &__sidebar-container {
    position: sticky;
    top: 40px;
  }

  &__right {
    position: relative;
    flex: auto;
    // allows stretching the content area for displaying the loading animation in the middle of the page
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0;

    @media (min-width: $screen-sm-min) {
      padding-top: 0;
    }

    @media (min-width: $screen-md-min) {
      padding: 0 40px;
    }
  }

  &__content {
    flex: auto;
    position: relative;
    margin-top: 10px;

    @media(min-width: $screen-sm-min) {
      margin: 0;
    }
  }

  &__permanent-notifications {
    &:not(:empty) {
      margin-bottom: 20px;
    }

    @keyframes dissapear {
      100% {
        max-height: 0;
        margin-bottom: 0;
      }
    }

    &_hidden {
      animation: dissapear 0s;
      animation-delay: 0.15s;
      animation-fill-mode: forwards;
    }
  }

  &__temporary-notifications {
    // allows container to grow with it's content until max-width is reached
    display: table;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    &-container {
      position: sticky;
      top: 20px;
      z-index: $z-index-notifications;

      @media(min-width: $screen-sm-min) {
        top: 30px;
      }
    }

    @media(min-width: $screen-sm-min) {
      left: auto;
      right: 0;
      transform: none;
      max-width: 60%;
    }

    @media(min-width: $screen-md-min) {
      max-width: 50%;
    }
  }

  &__footer {
    flex: none;
  }
}
