@import '~@setapp/ui-kit/styles/variables';

.outer-layout {
  $layout-content-padding-x: 20px;

  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex: auto;

  &__container {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }

  &__header {
    z-index: 100;
    animation: fadeIn 0.3s forwards;

    @keyframes fadeIn {
      0% {
        opacity: 0;
        visibility: hidden;
      }

      100% {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__main {
    width: 100%;
    max-width: 1080px;
    min-width: 320px;
    padding-top: 30px;
    padding-bottom: 20px;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    z-index: 1;
  }

  &__footer {
    flex-shrink: 0;
  }

  &__notifications {
    $margin-horizontal: 20px;
    display: table; // have no idea how it's work, but it allows container to grow with it's content till max-width
    position: absolute;
    max-width: calc(100% - #{$margin-horizontal * 2});
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);

    @media(min-width: $screen-sm-min) {
      left: auto;
      right: 40px;
      transform: none;
      max-width: 60%;
    }

    @media(min-width: $screen-md-min) {
      max-width: 50%;
    }
  }

  &__permanent-notifications {
    padding-left: $layout-content-padding-x;
    padding-right: $layout-content-padding-x;

    @media(min-width: $screen-md-min) {
      padding-left: $layout-content-padding-x * 2;
      padding-right: $layout-content-padding-x * 2;
    }
  }
}
