@import '~@setapp/ui-kit/styles/variables';
@import '~@setapp/ui-kit/styles/mixins';

.unconfirmed-email-notification {
  &__container {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    @include word-break;
  }

  &__button {
    margin-left: 30px;
    flex: none;
  }
}

@media (max-width: $screen-md-max) {
  .unconfirmed-email-notification {
    &__container {
      flex-direction: column;
      align-items: flex-start;
    }

    &__button {
      margin-left: 0;
      margin-top: 15px;
    }
  }
}
