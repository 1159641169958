@import '~@setapp/ui-kit/styles/variables';

.payments-details-plans {
  display: flex;
  flex-direction: column;
  margin-bottom: -28px;

  @media (max-width: $screen-xs-max) {
    padding: 0 10px;
  }

  &__button-container {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: $white;
    padding: 20px;
  }

  &__button {
    height: 52px;
  }
}
