@import '~@setapp/ui-kit/styles/variables';

.promo-banner {
  flex-flow: column nowrap;
  display: flex;
  margin-top: 48px;
  padding: 28px 20px 36px;
  align-items: center;
  border-radius: 15px;
  background-color: #faf3ed;

  @media (min-width: $screen-sm-min) {
    padding: 32px 48px;
  }

  @media (min-width: $screen-md-min) {
    flex-direction: row;
  }

  &__content {
    margin-top: 28px;
    align-self: stretch;

    @media (min-width: $screen-md-min) {
      margin-left: 60px;
      margin-top: 0;
    }
  }

  &__button {
    min-width: 180px;
    width: 100%;

    @media (min-width: $screen-sm-min) {
      width: auto;
    }
  }

  &__image {
    @media (min-width: $screen-md-min) {
      max-width: 100%;
    }
  }

  &__illustration {
    min-width: 190px;
    text-align: center;
  }
}
