@import '~@setapp/ui-kit/styles/variables';

@keyframes background-appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes background-disappear {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes popup-appear {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes popup-disappear {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(120%);
    visibility: hidden;
  }
}

.popup-about-download {
  &__background {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 30;

    animation: background-appear 0.5s ease-out forwards;

    &_disappear {
      animation: background-disappear 0.3s ease-out forwards;
      animation-delay: 0.4s;
    }
  }

  &__content {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 550px;
    padding: 20px;
    background-color: #fff;
    border-radius: 20px 20px 0 0;

    animation: popup-appear 0.3s ease-out forwards;

    &_disappear {
      animation: popup-disappear 0.3s ease-out forwards;
    }
  }

  &__image {
    margin: -100px auto 32px;
  }
}
