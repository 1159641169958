@import '~@setapp/ui-kit/styles/mixins';
@import '~@setapp/ui-kit/styles/variables';

.try-family-plan-page {
  &__hero {
    border-bottom: 1px solid $very-light-pink;
    padding-bottom: 36px;
    display: flex;
    column-gap: 40px;
    justify-content: space-between;
  }

  &__hero-cta {
    min-width: 200px;
  }

  &__hero-image {
    display: none;

    @media (min-width: $screen-lg-min) {
      display: block;
      margin-top: 28px;
    }
  }

  &__features {
    border-bottom: 1px solid $very-light-pink;
    padding: 60px 0;
  }

  &__features-list {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12px;
    row-gap: 12px;

    @media (min-width: $screen-lg-min) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__features-item {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &__faq {
    margin-top: 60px;
  }

  &__faq-list {
    margin-top: 32px;
    margin-left: -20px;
  }
}
