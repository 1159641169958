@import '~@setapp/ui-kit/styles/variables';

.check-icon {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: $sea;
  display: inline-block;
  align-items: center;
  justify-content: center;
}
