.account-deleted-page {
  &__logo {
    display: inline-block;
  }

  &__primary-content {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // compensate space taken by the Setapp logo for the proper content's vertical alignment
    padding-bottom: 36px;
  }

  &__secondary-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding: 20px;
  }

  &__secondary-image {
    flex: none;
    align-self: center;
  }
}
