@import '~@setapp/ui-kit/styles/variables';

.ios-advanced-card {
  &__price-plan {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid $very-light-pink;
  }

  &__plan-header {
    display: flex;
    justify-content: space-between;
  }

  &__plan-left {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__plan-label {
    border-radius: 8px;
    background: #f6cf98;
    text-align: center;
    padding: 6px 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: $font-weight-medium;
    line-height: 18px;
    align-self: flex-start;
  }

  &__plan-title {
    font-size: 20px;
    font-weight: $font-weight-bold;
    line-height: 28px;
    margin-bottom: 4px;
  }

  &__plan-right {
    color: #26262b;
    font-size: $font-size-h6;
    line-height: 24px;
  }
}
