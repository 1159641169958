@import '~@setapp/ui-kit/styles/mixins';
@import '~@setapp/ui-kit/styles/variables';

.signup-payment-details-form {
  @include vertical-center-content;
  position: relative;
  background: $white;

  @media (max-width: $screen-xs-max) {
    padding: 0 10px;
  }

  &__container {
    @media (max-width: $screen-sm-max) {
      margin: 0 auto 52px;
    }
  }
}
