.successful-trial-activation-page {
  margin-top: 50px;
  display: flex;
  width: 100%;
  justify-content: center;

  &__image {
    margin-bottom: 16px;
    max-width: 100%;
    height: auto;
  }
}
