@import '~@setapp/ui-kit/styles/variables';

.oauth-login-form {
  &__title {
    margin-top: 12px;
    margin-bottom: 24px;

    @media (min-width: $screen-sm-min) {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }

  &__social-buttons,
  &__social-buttons-caption {
    margin-top: 20px;

    @media (min-width: $screen-sm-min) {
      margin-top: 32px;
    }
  }

  &__footer {
    margin-top: 48px;

    @media (min-width: $screen-sm-min) {
      margin-top: 64px;
    }
  }
}
