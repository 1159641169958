@import '~@setapp/ui-kit/styles/variables';

.ai-offer-card__wrapper {
  isolation: isolate; // so that ::before gradient is still shown with -1 z-index
  width: 100%;
  max-width: 380px;

  @media (max-width: $screen-md-max) {
    max-width: 100%;
  }
}

.ai-offer-card {
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 15px;
  border: 2px solid $very-light-pink;
  background: $white;

  @media (max-width: $screen-xs-max) {
    padding: 40px 20px 20px;
  }

  &_current {
    border-color: $heather;
  }

  &_promoted {
    height: calc(100% + 28px);
    margin-top: -14px;
    padding-top: 44px;

    position: relative;
    border-color: transparent;
    background-clip: padding-box;

    &::before {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      border-radius: inherit;
      background: linear-gradient(to bottom, #7885fa 20%, #dcadda 60%, #ffbfb1 100%);
      z-index: -1;
    }

    @media (max-width: $screen-md-max) {
      height: auto;
      margin-top: 0;
    }
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid $very-light-pink;
    padding-bottom: 28px;
  }

  &__body {
    flex-grow: 1;
    min-height: 280px;

    @media (max-width: $screen-md-max) {
      min-height: 0;
      margin-bottom: 40px;
    }
  }

  &__list {
    list-style-type: none;
    padding: 20px 0 0;
    margin: 0;
    min-height: 100px;
  }

  &__list-item {
    padding-left: 32px;
    background: url('images/list-item.svg') top 7px left no-repeat;
  }

  &__month {
    font-size: 26px;
  }

  &__discount {
    text-decoration: line-through;
    color: $heather;
    margin-right: 10px;
    font-size: 26px;
  }

  &__best-label {
    position: absolute;
    top: -1px;
    right: -1px;
    height: 28px;
    padding: 0 8px 4px 10px;
    border-radius: 0 6px;
    background-color: #7885fa;
    color: $white;
    letter-spacing: 1px;
  }

  &__credits {
    font-size: 23px;
    font-weight: 600;
    line-height: 1.39;
    color: $dark-grey;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
  }
}
