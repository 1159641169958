@import '~@setapp/ui-kit/styles/variables';

.info-block {
  &__main {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    @media (max-width: $screen-xs-max) {
      flex-direction: column;
    }
  }

  &__description {
    color: $heather;
  }
}
