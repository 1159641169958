@import '~@setapp/ui-kit/styles/variables';

.oauth-error {
  margin-top: 48px;

  @media (min-width: $screen-sm-min) {
    margin-top: 64px;
  }

  &__image {
    width: 100%;
  }

  &__description {
    padding: 0;

    @media (min-width: $screen-sm-min) {
      padding: 0 36px;
    }
  }
}
