@import '~@setapp/ui-kit/styles/variables';

.braintree-field {
  color: $heather;

  &_focus {
    border-color: $input-border-focus;
  }

  &__tooltip-button {
    display: inline-block;
    font-size: 0;
    padding: 0;
    border: none;
    vertical-align: top;
    position: relative;
    top: 3px;
    margin-left: 5px;
  }
}
