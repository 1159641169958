.invalid-family-invite {
  &__primary-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__secondary-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
