@import '~@setapp/ui-kit/styles/variables';

.price-plan-card {
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 15px;
  border: 2px solid $very-light-pink;
  background: $white;
  height: 100%;
  margin-bottom: 12px;

  @media (max-width: $screen-xs-max) {
    padding: 40px 20px 20px;
  }

  &_current {
    border-color: $heather;

    .price-plan-card__header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &_primary {
    border-color: $heather;

    @media (min-width: $screen-md-max) {
      padding: 52px 48px;
      margin-top: -12px;
      height: calc(100% + 24px);
    }
  }

  &__header {
    border-bottom: 1px solid $very-light-pink;
    padding-bottom: 28px;
  }

  &_with-subtitle {
    padding-top: 28px;

    &.price-plan-card_primary {
      padding-top: 40px;
    }

    .price-plan-card__header {
      padding-bottom: 20px;
    }
  }

  &__body {
    flex-grow: 1;

    @media (max-width: $screen-md-max) {
      margin-bottom: 40px;
    }
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;

    &-item {
      padding-left: 32px;
      background: url('images/list-item.svg') top 7px left no-repeat;
    }
  }
}
