@import '~@setapp/ui-kit/styles/variables';

.current-plan-step {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__billing-period {
    margin-top: 24px;
    border-top: 1px solid $very-light-pink;
    padding-top: 24px;
  }

  &__cancel {
    border-top: 1px solid $very-light-pink;
    padding: 32px 0 40px;

    &-button {
      border: none;
      padding: 0;
      color: $red;
      background: none;

      &:hover,
      &:focus {
        color: lighten($red, 10%);
        text-decoration: none;
      }
    }

    &-description {
      margin-top: 8px;
      color: $heather;
    }
  }
}
