@import '~@setapp/ui-kit/styles/mixins';
@import '~@setapp/ui-kit/styles/variables';

.referral-social {
  display: flex;
}

.referral-link {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  @media(max-width: $screen-sm-max) {
    flex-direction: column;
  }

  &__input {
    /* stylelint-disable-next-line declaration-no-important */
    background-color: $white !important;
    max-width: 480px;

    @media(max-width: $screen-sm-max) {
      max-width: none;
    }
  }

  &__container {
    flex: auto;
    max-width: 700px;

    @media(max-width: $screen-sm-max) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &__input-container {
    display: flex;
    flex-direction: row;

    @media(max-width: $screen-sm-max) {
      flex-direction: column;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    margin-left: 60px;

    @media(max-width: $screen-sm-max) {
      width: 100%;
      margin-left: 0;
    }
  }

  &__button {
    min-width: 200px;
    margin-left: 20px;

    @media(max-width: $screen-sm-max) {
      width: 100%;
      margin: 20px 0;
    }
  }
}

.btn-referral-facebook {
  @include social-button(#4267b2);
  background-image: url('images/facebook.svg');

  @media(max-width: $screen-xs-min) {
    margin-left: 0;
    margin-right: 4px;
  }
}

.btn-referral-whatsapp {
  @include social-button(#25d366);
  background-image: url('images/whatsapp.svg');

  @media(max-width: $screen-xs-min) {
    margin-left: 0;
    margin-right: 8px;
  }
}

.btn-referral-twitter {
  @include social-button(#1da1f2);
  background-image: url('images/twitter.svg');

  @media(max-width: $screen-xs-min) {
    margin-left: 4px;
  }
}

.btn-referral-facebook,
.btn-referral-twitter,
.btn-referral-whatsapp {
  padding: 0;
  width: 52px;
  height: 52px;
  background-position: center center;
}
