@import '~@setapp/ui-kit/styles/variables';

$tailPadding: 30px;
$animationTransform: 100%;

.balloon {
  @mixin state-top-hidden($animationTransform) {
    opacity: 0.01;
    transform: translateY($animationTransform);
  }

  @mixin state-visible {
    opacity: 1;
    transform: translateY(0);
  }

  @mixin state-bottom-hidden($animationTransform) {
    opacity: 0.01;
    transform: translateY(-$animationTransform);
  }

  @mixin animation-transition() {
    transition: opacity 500ms, transform 500ms;
  }

  padding: 20px;
  position: relative;
  background-color: #fff;
  box-shadow: 0 5px 20px 0 rgba(#000, 0.25);
  border-radius: 6px;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 15px;
    height: 15px;
    transform: rotate(135deg);
    background-color: #fff;
    border: 1px solid;
    border-color: transparent transparent #e5e5e5 #e5e5e5;
  }

  &-without-tail::before {
    content: none;
  }

  .balloon-close-icon {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  &-right {
    &::before {
      right: -7px;
      transform: rotate(-135deg);
    }

    &-bottom {
      &::before {
        bottom: $tailPadding;
      }
    }

    &-top {
      .balloon-close-icon {
        left: 15px;
        right: initial;
      }

      &::before {
        top: $tailPadding;
      }
    }
  }

  &-left {
    &::before {
      left: -9px;
      transform: rotate(45deg);
    }

    &-top {
      &::before {
        top: $tailPadding;
      }
    }

    &-bottom {
      &::before {
        bottom: $tailPadding;
      }
    }
  }

  &-top {
    &::before {
      top: -8px;
    }

    &-right {
      > .balloon-close-icon {
        left: 15px;
        right: initial;
      }

      &::before {
        right: $tailPadding;
      }
    }

    &-left {
      &::before {
        left: $tailPadding;
      }
    }

    &-enter {
      @include state-top-hidden($animationTransform);

      &.balloon-top-enter-active {
        @include animation-transition;
        @include state-visible;
      }
    }

    &-exit {
      @include state-visible;

      &.balloon-top-exit-active {
        @include animation-transition;
        @include state-top-hidden($animationTransform);
      }
    }
  }

  &-bottom {
    &::before {
      bottom: -7px;
      transform: rotate(-45deg);
    }

    &-left {
      &::before {
        left: $tailPadding;
      }
    }

    &-right {
      &::before {
        right: $tailPadding;
      }
    }

    &-enter {
      @include state-bottom-hidden($animationTransform);

      &.balloon-bottom-enter-active {
        @include animation-transition;
        @include state-visible;
      }
    }

    &-exit {
      @include state-visible;

      &.balloon-bottom-exit-active {
        @include animation-transition;
        @include state-bottom-hidden($animationTransform);
      }
    }
  }
}
