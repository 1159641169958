@import '~@setapp/ui-kit/styles/variables';

.permission-item {
  display: flex;
  margin-bottom: 24px;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 32px;
  }

  &__icon {
    width: 48px;
    height: 48px;
  }

  &__content {
    margin-left: 16px;

    @media (min-width: $screen-sm-min) {
      margin-left: 24px;
    }
  }
}
