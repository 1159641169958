@import '~@setapp/ui-kit/styles/variables';

.price-plan-radio {
  margin-bottom: 44px;

  &_with-promo-text {
    margin-bottom: 0;
  }

  &__title {
    display: inline-flex;
    align-items: center;
    gap: 0 8px;

    @media (max-width: $screen-xs-min) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__current-plan-label {
    font-size: 16px;
    color: $heather;
  }

  &__ai-plan-label {
    border-radius: 8px;
    padding: 2px;
    display: inline-block;
    background-image: linear-gradient(111deg, #7885fa 0%, #dcadda 58%, #ffbfb1 88%);
  }

  &__ai-plan-label-content {
    font-size: 12px;
    padding: 2px 8px;
    background: $white;
    border-radius: 6px;
  }

  &__description {
    color: $heather;
  }

  &__promo-text {
    color: $sea;
  }

  &__price {
    color: $heather;
  }
}
