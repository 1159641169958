@import '~@setapp/ui-kit/styles/variables';

.devices-page {
  &__header {
    padding-bottom: 44px;
    border-bottom: 1px solid $very-light-pink;
    margin-bottom: 60px;

    &_ios-only {
      padding-bottom: 0;
      border-bottom: 0;
      margin-bottom: 24px;
    }
  }

  &__downgrade-notification {
    color: $red;
    padding-left: 24px;
    background: url('images/icons-warning.svg') no-repeat 0 4px;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__ios-list-title {
    display: flex;
    align-items: baseline;
  }

  &__disconnect-ios-btn {
    margin-left: auto;
    font-weight: 400;
  }

  &__subtitle {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__beta-label {
    display: inline-block;
    padding: 0 8px;
    border-radius: 10px;
    color: $white;
    background: $dusty-orange;
  }
}
