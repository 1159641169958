@import '~@setapp/ui-kit/styles/variables';

.devices-list {
  list-style: none;
  padding: 12px 0 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  &-row {
    margin-bottom: 48px;
    flex: 0 0 50%;

    @media (max-width: $screen-sm-max) {
      flex: 0 0 100%;
    }

  }
}
