@import '~@setapp/ui-kit/styles/variables';

.payment-history-empty-state {
  margin-top: 32px;
  color: $heather;

  &__icon {
    margin-right: 8px;
    margin-top: -5px;
  }
}
