@import '~@setapp/ui-kit/styles/variables';

.billing-period-radio {
  &__description_disabled {
    color: $heather;
  }

  &__price_disabled {
    color: $heather;
  }

  &__title_promo {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media(max-width: $screen-xs-max) {
      flex-direction: column;
      align-items: start;
    }
  }

  &__promo {
    margin-left: 12px;
    border-radius: $border-radius-base;
    padding: 2px 7px;
    background-color: $sea;
    color: $white;
    font-size: $font-size-md;

    @media(max-width: $screen-xs-max) {
      margin-left: 0;
    }
  }
}
