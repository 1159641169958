@import '~@setapp/ui-kit/styles/variables';

.authorization-form {
  &__submit {
    margin-bottom: 16px;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 20px;
    }
  }
}
