@import '~@setapp/ui-kit/styles/mixins';
@import '~@setapp/ui-kit/styles/variables';

.invite-friends-btn {
  padding-left: 10px;
  padding-right: 10px;
  transition: background-color 200ms ease-in-out, border 200ms ease-in-out;

  &_copied, &_copied:hover, &_copied:focus {
    background-color: #479a94;
    border-color: #479a94;
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &__container {
    position: relative;
  }

  &__balloon {
    position: absolute;
    width: 80vw;
    z-index: 1040;
    border-radius: 6px;
    top: 56px;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: $screen-xs-min) {
      width: 380px;
    }

    @media (min-width: $screen-sm-min) {
      transform: unset;
      left: unset;
      right: 0;
    }

    .balloon::before {
      right: 48%;

      @media (min-width: $screen-sm-min) {
        right: 60px;
      }
    }

    .balloon-top-enter {
      opacity: 0;
      transform: translateY(20px);

      &.balloon-top-enter-active {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .balloon-top-exit {
      opacity: 1;
      transform: translateY(0);

      &.balloon-top-exit-active {
        opacity: 0;
      }
    }
  }
}
