@import '~@setapp/ui-kit/styles/variables';

.family-members-item {
  padding: 8px 20px;
  margin-bottom: 8px;
  border-radius: 6px;

  @media (max-width: $screen-md-max) {
    margin-bottom: 12px;
  }

  &:hover {
    @media (min-width: $screen-md-max) {
      background-color: $light-silver;
    }
  }

  &__avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #e6a873;
    color: $white;
    text-align: center;
    line-height: 33px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 20px;

    @media (max-width: $screen-sm-min) {
      display: none;
    }
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__status, &__actions {
    @media (min-width: $screen-xs-max) {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  &__status {
    @media (max-width: $screen-md-max) {
      text-align: right;
    }
  }

  &__actions {
    font-size: 0;

    @media (min-width: $screen-md-max) {
      text-align: right;
    }

    &-button {
      @media (max-width: $screen-md-max) {
        margin-top: 8px;
      }

      @media (max-width: $screen-sm-min) {
        margin-top: 12px;
      }
    }

    &-button:first-child {
      margin-right: 20px;
    }

    &-button:last-child {
      margin-right: 0;
    }
  }
}
