@import '~@setapp/ui-kit/styles/variables';

.family-invite {
  &__primary-content,
  &__secondary-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__primary-content {
    position: relative;
    padding: 72px 0 40px;
  }

  &__logo {
    position: absolute;
    top: 28px;
    left: 0;

    @media(min-width: $screen-sm-min) {
      top: 40px;
    }
  }

  &__primary-title {
    margin: 0 0 16px;
  }

  &__secondary-content {
    background: $off-white;
    border-radius: 20px;
    align-items: center;
    padding: 80px 0;
  }

  &__secondary-content > * {
    max-width: 560px;
    text-align: center;
  }

  &__secondary-title {
    margin: 56px 0 16px;
  }
}
