@import '~@setapp/ui-kit/styles/variables';

.panel-box-content {
  padding: 30px 20px;
  flex: 1 0 auto;

  @media (min-width: $screen-sm-min) {
    padding: 30px 40px;
  }
}
