@import '~@setapp/ui-kit/styles/variables';

.notifications {
  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item + &__item {
    margin-top: 20px;
  }
}

//notification list animation
.notifier {
  &-enter {
    opacity: 0.01;

    &-active {
      opacity: 1;
      transition: opacity 200ms ease-in;
    }
  }

  &-exit {
    opacity: 1;

    &-active {
      opacity: 0;
      transition: opacity 200ms ease-in;
    }
  }
}
