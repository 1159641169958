@import '~@setapp/ui-kit/styles/variables';

.referral {
  &__bottom-help {
    padding-top: 48px;
    border-top: 1px solid #e5e5e5;
    margin-top: 48px;
  }
}

.mc-promo-banner {
  flex-flow: column nowrap;
  display: flex;
  margin-top: 48px;
  padding: 28px 20px 36px;
  align-items: center;
  border-radius: 15px;
  background-color: #faf3ed;

  @media (min-width: $screen-sm-min) {
    padding: 32px 48px;
  }

  @media (min-width: $screen-md-min) {
    flex-direction: row;
  }

  @media (min-width: $screen-lg-min) {
    padding: 36px 60px 36px 48px;
  }

  &__content {
    margin-top: 28px;
    align-self: center;

    @media (min-width: $screen-md-min) {
      margin-left: 48px;
      margin-top: 0;
    }
  }
}
