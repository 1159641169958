@import '~@setapp/ui-kit/styles/variables';

.pick-active-device-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  color: $btn-default-color;
  box-shadow: inset 0 0 0 1px $btn-default-color;
  padding: 10px 20px;
  position: relative;

  @media (max-width: $screen-xs-min) {
    padding: 20px;
  }

  &:hover {
    box-shadow: inset 0 0 0 1px darken($btn-default-color, 10%);
    color: darken($btn-default-color, 10%);
  }

  &_active {
    box-shadow: inset 0 0 0 1px $brand-danger;
    color: $brand-danger;

    &:hover {
      box-shadow: inset 0 0 0 1px darken($brand-danger, 10%);
      color: darken($brand-danger, 10%);
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
  }

  &__subtitle {
    margin-top: 10px;
  }

  &__icon {
    margin-left: 20px;

    @media (max-width: $screen-xs-min) {
      display: none;
    }
  }
}
