@import '~@setapp/ui-kit/styles/mixins';

.family-member {
  &__description {
    @include word-break;
  }

  &__inactive-status {
    display: flex;
    align-items: center;
  }
}
