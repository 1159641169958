@import '~@setapp/ui-kit/styles/variables';

.fullscreen-layout-wide {
  width: 100%;

  &__container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 60px 20px 40px;

    @media (min-width: $screen-md-max) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @media (max-width: $screen-xs-max) {
      padding: 20px 10px 40px;
    }
  }

  &__content {
    padding-left: 92px;
    padding-right: 92px;
    position: relative;

    @media (max-width: $screen-md-max) {
      padding-left: 26px;
      padding-right: 26px;
    }

    @media (max-width: $screen-xs-max) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__setapp-logo {
    margin-bottom: 68px;

    @media (max-width: $screen-xs-max) {
      margin-bottom: 28px;
    }
  }
}
