@import '~@setapp/ui-kit/styles/variables';
@import '~@setapp/ui-kit/styles/mixins';

.subscription-blocked-notification {
  &__actions {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px 24px;

    @media (min-width: $screen-md-min) {
      flex-direction: row;
      align-items: baseline;
    }
  }

  &__update-details-link {
    color: $dark-grey;
    text-decoration: underline;

    &:hover, &:focus {
      color: $dark-grey;
    }
  }
}
