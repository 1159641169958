@import '@setapp/ui-kit/styles/variables';

.desktop-download-reminder {
  &__container {
    display: flex;
    flex-direction: column;

    @media (min-width: $screen-md-min) {
      flex-direction: row;
    }
  }

  &__icon {
    margin: auto 0;
    margin-right: 16px;
  }

  &__action {
    width: 180px;
    margin-left: 20px;
    align-self: center;
    flex-shrink: 0;
  }
}
