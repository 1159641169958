@import '~@setapp/ui-kit/styles/variables';

.problem-banner {
  background-color: $beige-light;
  padding: 14px 20px;
  border-radius: 15px;

  &__title {
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    line-height: 24px;
    margin-bottom: 4px;
  }

  &__description {
    font-size: $font-size-md;
    line-height: 24px;
    margin: 0;
    padding: 0;
  }
}
