@import '~@setapp/ui-kit/styles/variables';

.signup-payment-details-description {
  @media (max-width: $screen-xs-max) {
    padding: 0 10px;
  }

  @media (max-width: $screen-sm-max) {
    max-width: 380px;
    margin: 0 auto 52px;
  }

  &__delimiter {
    border-bottom: 1px solid $very-light-pink;
    margin: 16px 0 24px;
  }

  &__title {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  &__feature-list {
    padding: 0;

    &-item {
      list-style: none;
      padding-left: 28px;

      &::before {
        background-image: url('images/bullets.svg');
        content: '';
        display: inline-block;
        height: 16px;
        width: 16px;
        margin-left: -28px;
        margin-right: 12px;
        position: relative;
        top: 2px;
      }
    }
  }

  &__discount {
    width: 50%;
    margin-left: 50%;
    text-align: center;
    padding: 8px 0;
  }
}
