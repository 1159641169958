@import '~@setapp/ui-kit/styles/variables';

.gift-cards-page {
  &__section-title {
    margin-top: 30px;
  }

  &__info {
    margin-top: 16px;
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid $very-light-pink;

    @media (min-width: $screen-md-min) {
      margin-bottom: 48px;
      padding-bottom: 48px;
    }
  }

  &__family-notification {
    display: inline-block;

    @media (min-width: $screen-lg-min) {
      max-width: 768px;
    }
  }
}
