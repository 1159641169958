@import '~@setapp/ui-kit/styles/variables';

.ios-apps-list {
  margin: 0 -15px;

  @media (min-width: $screen-md-min) {
    display: flex;
    flex-flow: row wrap;
  }

  &__item {
    padding: 15px;
    display: flex;
    outline: none;

    @media (min-width: $screen-md-min) {
      flex: 0 0 50%;

      &:focus {
        // reset outline for firefox
        outline: 1px dotted #212121;
        // for chrome, safari
        outline: 5px auto -webkit-focus-ring-color;
      }
    }

    @media (min-width: $screen-lg-min) {
      flex-basis: calc(100% / 3);
    }
  }

  &__btn {
    @media (min-width: $screen-md-min) {
      display: none;
    }
  }

  &__icon-container {
    width: 120px;
    height: 120px;
    margin-right: 15px;
    padding: 12px;
    cursor: pointer;

    transform: scale(1);
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__icon {
    border-radius: 22px;
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      border-radius: 22px;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
    }
  }

  &__info {
    height: fit-content;
    margin-top: 12px;
  }

  &__description {
    font-weight: 500;
    margin-bottom: 4px;
  }

  &__name {
    color: $dark-grey;
    margin-bottom: 10px;
    opacity: 0.5;
  }
}
