@import '~@setapp/ui-kit/styles/variables';
@import '~@setapp/ui-kit/styles/mixins';

.switch-to-family-plan {
  &__price {
    @include word-break;
    color: $link-color;
    font-size: 50px;
    font-weight: $font-weight-bold;
    letter-spacing: -3px;
    line-height: 1.175;
  }

  &__title {
    margin: 0;
  }

  &__button {
    margin-top: 28px;
  }
}
