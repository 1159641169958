@import '~@setapp/ui-kit/styles/variables';

.family-members {
  list-style: none;
  padding-left: 0;
  margin: 0 -20px;

  &__actions {
    text-align: right;

    @media (max-width: $screen-md-max) {
      display: none;
    }
  }

  &__status {
    @media (max-width: $screen-md-max) {
      text-align: right;
    }
  }
}
