.referral-signup-primary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 40px 0;

  &__logo {
    margin-bottom: 40px;
    align-self: flex-start;
  }

  &__content {
    margin: auto;
  }
}
