@import '~@setapp/ui-kit/styles/variables';

.oauth-login-step {
  &__footer {
    margin-top: 48px;

    @media (min-width: $screen-sm-min) {
      margin-top: 64px;
    }
  }
}
