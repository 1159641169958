.setapp-description-section-for-desktop {
  padding-left: 40px;

  &__legacy-setapp-icon {
    margin-bottom: 20px;
    margin-left: -20px;
  }

  &__setapp-icon {
    margin-bottom: 20px;
    margin-left: 8px;
  }

  &__legacy-button {
    margin-bottom: 24px;
    padding: 9px 28px;
  }

  &__button {
    margin-bottom: 32px;
    padding: 14px 28px;
  }

  &__text-nowrap {
    white-space: nowrap;
  }
}
