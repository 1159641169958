@import '~@setapp/ui-kit/styles/variables';

.device-item {
  display: flex;

  &__content {
    width: 100%;
    max-width: 260px;
    padding-right: 20px;
  }

  &__button {
    display: inline-block;
    margin: 0 20px 5px 0;
  }
}
