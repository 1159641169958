@import '~@setapp/ui-kit/styles/variables';

.outer-form {
  &__container {
    width: 100%;
    max-width: 430px;
    margin: auto;
    pointer-events: auto; // required for referral sign up to allow scrolling when the cursor is over the fixed form

  }

  &__wrapper {
    position: relative;
    background: #fff;
    border-radius: $border-radius-large;
    padding: 14px 50px 25px;
    box-shadow: 0 5px 20px 0 #d9dada;
    overflow-wrap: break-word;

    @media (max-width: $screen-xs-max) {
      padding: 14px 20px 25px;
    }
  }
}
