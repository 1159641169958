@import '~@setapp/ui-kit/styles/variables';

.family-subscription-activated {
  &__primary-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    @media (min-width: $screen-lg-min) {
      white-space: nowrap;
    }
  }

  &__secondary-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
