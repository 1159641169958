@import '~@setapp/ui-kit/styles/variables';
@import '~@setapp/ui-kit/styles/mixins';

.successful-ai-offer-activation {
  &__primary-content {
    height: 100%;
    min-width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: $screen-sm-min) {
      min-width: 0;
    }
  }

  &__secondary-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__buttons {
    width: 380px;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media (max-width: $screen-sm-min) {
      width: 100%;
    }
  }
}
