@import '~@setapp/ui-kit/styles/mixins';

.device-seat-checkbox {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &_disabled {
    opacity: 0.5;
  }

  &__icon {
    flex: none;
  }

  &__details {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__name {
    @include word-break;
  }
}
