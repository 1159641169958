@import '~@setapp/ui-kit/styles/variables';

.ios-collection-page {
  &__illustration-container {
    margin: 16px -20px 0;

    @media (min-width: $screen-sm-min) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__illustration {
    width: 100%;
    height: 70vw;
    background-image: url('../ios-apps/images/starter-pack.png');
    background-color: #dbeaea;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom center;

    @media (min-width: $screen-sm-min) {
      border-radius: 16px;
    }

    &_stress {
      background-image: url('../ios-apps/images/stress-less.png');
      background-color: #e6c3a5;
    }
  }
}
