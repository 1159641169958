@import '~@setapp/ui-kit/styles/mixins';

.paypal-form {
  padding-top: 32px;

  .form-group {
    margin-bottom: 24px;
  }

  .help-block {
    margin: 8px 0 0;
  }

  &-submit-btn {
    margin-bottom: 30px;
  }

  &-error {
    margin-top: 15px;
  }

  &-description {
    margin-bottom: 24px;
  }

  &__vat-fields {
    margin-bottom: 24px;
  }
}
