@import '~@setapp/ui-kit/styles/variables';

.upgrade-card {
  &__price-plan {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid $very-light-pink;
  }

  &__plan-header {
    display: flex;
    justify-content: space-between;
  }

  &__plan-title {
    font-size: 20px;
    font-weight: $font-weight-bold;
    line-height: 28px;
    margin-bottom: 4px;
  }

  &__plan-right {
    color: #26262b;
    font-size: $font-size-h6;
    line-height: 24px;
  }

  &__logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
  }

  &__description {
    line-height: 32px;
    letter-spacing: 0.5px;
  }

  &__list {
    padding: 0;
  }

  &__list-item {
    list-style: none;
    padding-left: 28px;
    margin-bottom: 8px;

    &::before {
      background-image: url('./images/bullet.svg');
      content: '';
      display: inline-block;
      height: 16px;
      width: 16px;
      margin-left: -28px;
      margin-right: 12px;
      position: relative;
      top: 2px;
    }
  }
}
