.paddle-form {
  &__container {
    // remove Paddle's internal padding
    margin: -17px -21px;

    @media (min-width: 336px) {
      margin: -17px -13px;
    }

    // Remove negative margins if there's no Paddle iframe loaded
    &:empty {
      margin: 0;
    }

    &_hidden {
      visibility: hidden;
      height: 0;
      // override negative margin until form is displayed
      margin: 0;
    }
  }

  &__loading {
    text-align: center;
  }
}
