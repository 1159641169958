.select-seats-form {
  &__error {
    margin-top: 32px;
  }

  &__submit {
    margin-top: 40px;
  }

  &__error + &__submit {
    margin-top: 12px;
  }
}
