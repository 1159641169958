.gift-card {
  &_inactive {
    opacity: 0.4;
  }

  &__title {
    margin-top: 20px;
    margin-bottom: 32px;
  }
}
