@import '~@setapp/ui-kit/styles/variables';

.redeem {
  // TODO: add these colors to the UI kit
  $light-gray: #f8f8f4;
  $pale: #f4f0e4;

  &__primary-content,
  &__secondary-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__primary-content {
    position: relative;
    padding-top: 72px;
  }

  &__logo {
    position: absolute;
    top: 28px;
    left: 0;

    @media(min-width: $screen-sm-min) {
      top: 40px;
    }
  }

  &__primary-title {
    margin: 0 0 16px;
  }

  &__secondary-content {
    background: $light-gray;
    border-radius: 20px;
    align-items: center;
  }

  &__secondary-content > * {
    max-width: 576px;
    text-align: center;
  }

  &__secondary-title {
    margin: 56px 0 12px;
  }

  &__secondary-label {
    background: $pale;
    border-radius: 20px;
    padding: 24px 32px;
    margin: 12px 0 24px;
  }

  &__secondary-advantages {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }

  &__secondary-advantages-item-caption {
    margin-top: 16px;
    text-align: center;
    padding: 0 20px;
  }

  &__secondary-advantages-item:nth-of-type(-n+2) &__secondary-advantages-item-caption {
    border-right: 1px solid $very-light-pink;
  }
}
