@import '~@setapp/ui-kit/styles/variables';

.app-layout-loading {
  animation: loadingAnimation 1s infinite;

  &__title {
    max-width: 220px;
    height: 24px;
    border-radius: $border-radius-base;
    background: $very-light-pink;
  }

  &__subtitle {
    max-width: 160px;
    height: 16px;
    border-radius: 4px;
    background: #f5f5f5;
  }

  &__text {
    max-width: 320px;
    height: 12px;
    border-radius: 2px;
    background: #f5f5f5;
  }
}

@keyframes loadingAnimation {
  0% {
    opacity: 100%;
  }

  50% {
    opacity: 50%;
  }

  100% {
    opacity: 100%;
  }
}
