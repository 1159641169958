.activate-ios-app-content {
  &__container {
    padding: 40px 0;
    min-height: 208px;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 128px;
  }
}
