.apps-grid {
  height: 100%;
  position: relative;

  &__content {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: -20px;
    bottom: -20px;
    left: 0;
    right: -20px;
    padding-left: 60px;
    overflow: hidden;
    width: calc(100% + 60px);
  }

  &__column {
    display: flex;
    flex-direction: column;
    height: 100%;

    &:nth-child(even) {
      margin-top: calc(12.5vh + 40px);
    }
  }

  &__icon-container {
    position: relative;
    width: 80px;
    min-height: 80px;
    margin-bottom: 25vh;
  }

  &__icon {
    width: 100%;

    &_single-app {
      position: absolute;
      width: 200px;
      left: -60px;
      top: -60px;
    }
  }
}
