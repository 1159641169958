/* stylelint-disable-next-line selector-max-type */
html,
body {
  height: 100%;
  overflow: hidden;
}

/* stylelint-disable-next-line selector-max-id */
#root {
  height: 100%;
}
