@import '~@setapp/ui-kit/styles/variables';

.password-requirements-popup {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;

  &__tooltip {
    opacity: 0;
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    margin: 0 0 0 20px;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;

    &_active {
      opacity: 1;
    }

    @media (min-width: $screen-lg-min) {
      display: block;
    }

    &::before {
      content: '';
      position: absolute;
      top: 15px;
      left: -6px;
      width: 20px;
      height: 20px;
      background-color: $white;
      transform: rotate(45deg);
      border-radius: $border-radius-base 0;
      z-index: 3;
    }

    &::after {
      content: '';
      position: absolute;
      top: 15px;
      left: -6px;
      width: 20px;
      height: 20px;
      transform: rotate(45deg);
      border-radius: $border-radius-base 0;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
      z-index: 1;
    }
  }

  &_left {
    left: -100%;
    margin-left: -12px;
    top: 0;
    right: auto;

    .password-requirements-popup__tooltip::before {
      right: -6px;
      left: auto;
    }

    .password-requirements-popup__tooltip::after {
      right: -6px;
      left: auto;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    }
  }

  &__list {
    position: relative;
    padding: 16px 62px 16px 16px;
    width: 300px;
    border-radius: $border-radius-base;
    background: $white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.1));
    list-style: none;
    z-index: 2;
  }

  &__item {
    position: relative;
    padding-left: 28px;
    font-size: $font-size-sm;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      background-image: url('images/gray.svg');
    }

    & + & {
      margin-top: 12px;
    }

    &_valid {
      color: $sea;

      &::before {
        background-image: url('images/green.svg');
      }
    }

    &_invalid {
      color: $dark-grey;

      &::before {
        background-image: url('images/red.svg');
      }
    }
  }
}
