@import '~@setapp/ui-kit/styles/variables';

.next-payment-info {
  &__price-row {
    display: flex;
    justify-content: space-between;
  }

  &__grace-period-docs-link {
    pointer-events: all;
  }

  &__delimiter {
    margin: 16px 0;
    border-top: 1px solid $very-light-pink;
  }
}
