@import '~@setapp/ui-kit/styles/variables';

.expandable {
  position: relative;

  .arrow {
    width: 0;
    height: 0;
    display: inline-block;
    position: relative;
    top: - ($line-height-computed * 0.5 - 2px);
    right: -3px;
    transition: 0.1s ease;
    text-align: left;
    transform: rotate(45deg);

    &::before,
    &::after {
      position: absolute;
      content: '';
      display: inline-block;
      width: 7px;
      height: 2px;
      background-color: $link-color;
      transition: 0.1s ease;
    }

    &::after {
      position: absolute;
      transform: rotate(90deg);
      top: -3px;
      left: 3px;
    }
  }

  &-show {
    .arrow {
      transform: rotate(45deg) translate(-3px, -3px);

      &::before {
        transform: translate(6px, 0);
      }

      &::after {
        transform: rotate(90deg) translate(6px, 0);
      }
    }
  }

  &__checkbox {
    margin: 0;
  }

  &-link {
    padding-right: 15px;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;

      .arrow {
        &::before,
        &::after {
          background-color: $link-hover-color;
        }
      }
    }
  }
}
