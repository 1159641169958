@import '~@setapp/ui-kit/styles/variables';

.family-signup {
  &__primary-content,
  &__secondary-content {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__primary-content {
    position: relative;
    padding-top: 72px;
  }

  &__logo {
    position: absolute;
    top: 28px;
    left: 0;

    @media(min-width: $screen-sm-min) {
      top: 40px;
    }
  }

  &__primary-title {
    margin: 0 0 32px;
  }

  &__secondary-content {
    background: $off-white;
    border-radius: 20px;
    align-items: center;
    padding: 40px 0;
  }

  &__secondary-content > * {
    max-width: 610px;
    text-align: center;
  }

  &__secondary-title {
    margin: 48px 0 0;
  }

  &__secondary-advantages {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }

  &__secondary-advantages-item-caption {
    margin-top: 16px;
    text-align: center;
    padding: 0 20px;
  }

  &__secondary-advantages-item:nth-of-type(-n+2) &__secondary-advantages-item-caption {
    border-right: 1px solid $very-light-pink;
  }
}
