@import '~@setapp/ui-kit/styles/variables';

.payment-history {
  &__cell {
    // need to rewrite Bootstrap's default alignment without writing the long Bootstrap selector
    /* stylelint-disable-next-line declaration-no-important */
    vertical-align: baseline !important;
  }

  &__date-cell {
    @media(min-width: $screen-sm-min) {
      width: 50%;
    }
  }
}
