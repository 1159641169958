.family-benefit {
  margin-bottom: 20px;
  position: relative;
  padding-left: 30px;
  font-size: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &__emoji {
    position: absolute;
    left: 0;
  }
}
