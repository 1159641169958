@import '~@setapp/ui-kit/styles/variables';

.setapp-mobile-problems {
  &__title {
    font-size: 32px;
    line-height: 36px;
    margin-top: 6px;
    margin-bottom: 20px;
  }

  &__description {
    line-height: 28px;
    font-size: 18px;
  }

  &__feature-list {
    padding: 0;
  }

  &__list-item {
    list-style: none;
    padding-left: 28px;

    &::before {
      background-image: url('../../images/bullet.svg');
      content: '';
      display: inline-block;
      height: 16px;
      width: 16px;
      margin-left: -28px;
      margin-right: 12px;
      position: relative;
      top: 2px;
    }

    &_error {
      color: $red;

      &::before {
        background-image: url('../../images/alert-bullet.svg');
      }
    }
  }
}
