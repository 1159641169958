@import '~@setapp/ui-kit/styles/variables';

.signup-form {
  &__social-buttons {
    padding-top: 24px;
  }

  &__note {
    font-weight: 400;
    color: #808287;
    line-height: 1.43;
  }

  &__password-requirements-popup-wrapper {
    position: relative;
  }

  &__captcha-container {
    display: flex;
    justify-content: center;
    transform: scale(1.09);
    margin-top: 20px;
  }
}
