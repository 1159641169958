@import '~@setapp/ui-kit/styles/mixins';

.credit-card-form {
  padding-top: 20px;

  &-submit-btn {
    margin-bottom: 20px;
  }

  &-error {
    margin-top: 16px;
  }

  &__vat-fields {
    margin-top: 4px;
    margin-bottom: 24px;
  }

  &__tooltip-content {
    max-width: 200px;
    font-size: 14px;
  }

  &__divider {
    position: relative;
    text-align: center;

    &-text {
      background: $white;
      display: inline-block;
      padding: 0 10px;
      position: relative;
    }

    &-line {
      position: absolute;
      width: 100%;
      height: 1px;
      top: 50%;
      margin-top: -0.5px;
      background: #e5e5e5;
    }
  }

  &__payment-container {
    padding-top: 32px;
  }
}

.apple-pay-button {
  display: inline-block;
  width: 100%;
  border-radius: 6px;
  height: 44px;
  margin-bottom: 20px;
  background: #000;
  transition: background-color 0.15s ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  &-appearance {
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: subscribe;
  }

  &-black {
    -apple-pay-button-style: black;
  }
}

.google-pay-container {
  border-radius: 6px;
  height: 0;
  overflow: hidden;
  width: 100%;

  &_active {
    margin-bottom: 20px;
    height: 44px;
  }

  .gpay-button {
    transition: background-color 0.15s ease-in-out;
  }

  .gpay-button:disabled {
    opacity: 0.5;
    /* stylelint-disable-next-line declaration-no-important */
    background-image: url('images/spinner.svg') !important;
    background-size: 34px;
  }
}
