.buttons-row {
  display: inline-flex;
  flex-wrap: wrap;
  margin: -6px -10px;

  &__item {
    flex: 1 0 auto;
    margin: 6px 10px;
  }
}
