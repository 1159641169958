@import '~@setapp/ui-kit/styles/variables';

.successful-registration-mobile {
  width: 100%;
  padding: 0 10px;

  @media (min-width: $screen-sm-min) {
    padding: 0 28px;
  }

  &__setapp-icon {
    width: 100px;
    height: 100px;

    @media (min-width: $screen-sm-min) {
      width: 120px;
      height: 120px;
    }
  }

  &__title {
    max-width: 512px;
  }

  &__button {
    width: 100%;

    @media (min-width: $screen-sm-min) {
      width: auto;
    }
  }
}
