.plans-comparison {
  width: 100%;
  table-layout: fixed;

  &__row {
    border-bottom: 1px solid #e5e5e5;
  }

  &__cell {
    padding: 20px 0;

    &:not(&:first-child) {
      text-align: center;
    }
  }

  &__title {
    margin: 0;
  }
}
