@import '~@setapp/ui-kit/styles/variables';
@import '~@setapp/ui-kit/styles/mixins';

.info-field {
  margin-top: 15px;

  &__title {
    color: $light-gray-color;
  }

  &__value {
    font-weight: 500;
  }

  &_horizontal {
    display: table;
    width: 100%;
    margin-bottom: $line-height-computed * 0.5;
    margin-top: 0;

    .info-field__title {
      display: table-cell;
      width: 33%;
    }

    .info-field__value {
      display: table-cell;
      padding-left: 5px;

      @include word-break;
    }
  }
}
