@import '~@setapp/ui-kit/styles/variables';
@import '~@setapp/ui-kit/styles/mixins';

.cancel-subscription-page {
  width: 100%;
  padding: 60px 20px;

  &__logo {
    width: 100%;
    max-width: 520px;
    margin: 0 auto 24px;

    @media (min-width: $screen-sm-min) {
      max-width: initial;
      padding: 0 110px;
    }
  }
}
