@import '~@setapp/ui-kit/styles/variables';

.activation-guide {
  &__instruction-container {
    margin: 0 -20px;
    position: relative;

    @media (min-width: $screen-sm-min) {
      margin: 0 -60px;
    }
  }

  &__thumbnail {
    position: relative;
    max-width: 100%;
    width: 100%;
    height: auto;
    z-index: 2;
    opacity: 1;
    transition: opacity 0.35s linear;

    &_loaded {
      opacity: 0;
    }
  }

  &__instruction {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
