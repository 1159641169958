@import '~@setapp/ui-kit/styles/variables';
@import '~@setapp/ui-kit/styles/mixins';

.business-account-details {
  margin-bottom: 25px;
  padding-bottom: 25px;

  &__item {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 9px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    flex: 50%;
    padding-right: 5px;
    color: $light-gray-color;
  }

  &__value {
    flex: 50%;
    font-weight: 500;

    @include word-break;
  }
}
