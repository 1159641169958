@import '~@setapp/ui-kit/styles/variables';

.oauth-recover-password-step {
  &__back-button {
    margin-top: 12px;

    @media (min-width: $screen-sm-min) {
      margin-top: 32px;
    }
  }

  &__title {
    margin-top: 12px;
    margin-bottom: 24px;

    @media (min-width: $screen-sm-min) {
      margin-top: 20px;
      margin-bottom: 32px;
    }
  }

  &__sub-title {
    margin-bottom: 24px;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 32px;
    }
  }
}
