@import '~@setapp/ui-kit/styles/variables';

.social-auth-welcome-page {
  max-width: 480px;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;

  @media(min-width: $screen-md-min) {
    max-width: 676px;
  }

  &__button {
    display: inline-block;

    @media(min-width: $screen-md-min) {
      width: initial;
    }
  }
}
