.signup-payment-details {
  margin: auto 0;
  padding-bottom: 60px;
  width: 100%;
  max-width: 480px;
}

// TODO: move to ui kit
.form-switcher {
  &__input {
    &:disabled ~ .form-switcher__label {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
