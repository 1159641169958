@import '~@setapp/ui-kit/styles/variables';

.activate-feature-select {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__select-container {
    position: relative;
    display: inline-block;
  }

  &__select {
    width: 100%;
    padding: 2px 25px 3px 0;
    background-color: transparent;
    background-image: none;
    border: none;
    appearance: none;
    cursor: pointer;
    font-size: $font-size-lg;
    color: $dark-grey;
    text-overflow: ellipsis;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 7px;
    margin-top: -3px;
    color: $dark-grey;
    z-index: -1;
  }
}
