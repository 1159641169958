@import '~@setapp/ui-kit/styles/variables';

.cookie-banner {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: $dark-grey;
  z-index: 200;
  padding: 12px 16px;
  border: solid 1px rgba($white, 0.25);
  border-radius: $border-radius-large;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  width: calc(100% - 24px);
  max-width: 500px;

  @media (min-width: $screen-xs-min) {
    align-items: center;
  }

  @media (min-width: $screen-lg-min) {
    left: 20px;
    transform: none;
  }

  &__text {
    color: $white;
  }
}
