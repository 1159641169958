@import '~@setapp/ui-kit/styles/variables';

.cancel-subscription-step-layout {
  &__banner {
    width: 100%;
    height: 104px;
    background: #f4efeb;
    border-radius: 10px;
    margin: 32px 0;
    position: relative;
  }

  &__banner-illustration {
    width: 220px;
    height: 158px;
    position: absolute;
    top: calc(50% - 1px);
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
