@import '~@setapp/ui-kit/styles/variables';

.paddle-details {
  &__secure-icon {
    margin-left: 6px;
    vertical-align: top;
  }

  &__description {
    font-size: 14px;
    color: $heather;
  }
}
