@import '~@setapp/ui-kit/styles/variables';

.countdown {
  isolation: isolate; // so that ::before gradient is still shown with -1 z-index
  width: 100%;
  margin-bottom: 60px;

  &__container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 80px;
    border: 8px solid transparent;
    border-radius: 20px;
    background-image: linear-gradient(84deg, $dark-grey 0%, #3a3844);
    background-clip: padding-box;
    color: $white;

    @media(max-width: $screen-md-max) {
      padding: 24px 40px;
    }

    @media(max-width: $screen-xs-max) {
      flex-direction: column;
      padding: 24px 20px;
      gap: 24px 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      border-radius: 15px;
      background: linear-gradient(to right, #7885fa 20%, #dcadda 60%, #ffbfb1 100%);
      z-index: -1;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @media(max-width: $screen-xs-max) {
      align-items: center;
    }
  }

  &__text-header {
    margin-bottom: 0;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.54;

    @media(max-width: $screen-xs-max) {
      font-size: 22px;
    }
  }

  &__text-description {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.33;
  }

  &__number {
    font-size: 26px;
    font-weight: 600;
    line-height: 1.33;
  }

  &__num-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0 12px;
    align-items: flex-end;

    @media(max-width: $screen-xs-max) {
      grid-template-columns: repeat(4, max-content);
      justify-items: flex-start;
    }
  }

  &__num-text {
    margin-bottom: 0;
    text-align: center;
  }
}
