// We use full size body modal to have full height for Cancel subscription button
// in the Current Plan step
$modal-width: 508px;

.manage-subscription {
  margin: 0 auto;
  padding: 0 6px; // compensate horizontal padding to match the same padding of regular modal
  width: 100%;
  max-width: $modal-width;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__title {
    margin: 0 auto;
    padding: 0 6px; // compensate horizontal padding to match the same padding of regular modal
    max-width: $modal-width;
  }

  &__back-button {
    margin: 0 auto 20px;
    padding: 0 6px; // compensate horizontal padding to match the same padding of regular modal
    width: 100%;
    max-width: $modal-width;
  }

  &__loader {
    margin: auto;
    text-align: center;
  }
}
