@import '~@setapp/ui-kit/styles/variables';

.signup-form-container {
  @media (max-width: $screen-xs-max) {
    padding-top: 20px;
  }
}

.signup-page {
  &__loading {
    margin: auto;
  }
}
