@import '@setapp/ui-kit/styles/variables';

.oauth-confirm-email {
  margin-top: 100px;
  max-width: 480px;

  &__click-here {
    margin-top: -3px;
  }
}
