@import '~@setapp/ui-kit/styles/variables';

.successful-registration-from-desktop {
  &__title {
    margin: 20px auto;
    max-width: 350px;

    @media(max-width: $screen-xs-max) {
      max-width: 300px;
    }
  }

  &__sub-title {
    max-width: 560px;
    margin: 0 auto 42px;

    @media(max-width: $screen-xs-max) {
      max-width: 330px;
    }
  }
}
