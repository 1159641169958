@import '~@setapp/ui-kit/styles/variables';
@import '~@setapp/ui-kit/styles/mixins';

.activate-new-user-flow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 40px 0;

  &__logo {
    margin-bottom: 40px;
  }

  &__content {
    margin: auto;
  }

  &__total-amount {
    margin-bottom: 16px;
  }

  &__delimiter {
    margin: 16px 0;
    border-top: 1px solid $very-light-pink;
  }
}
