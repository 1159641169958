.social-auth-buttons {
  display: flex;
  flex-flow: row nowrap;

  &-item {
    flex: 1;
    padding: 0 9px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &:only-child {
      .btn-social {
        padding-right: 44px;
      }
    }
  }
}
