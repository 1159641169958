@import '~@setapp/ui-kit/styles/variables';
@import '~@setapp/ui-kit/styles/mixins';

.family-member-item {
  display: flex;
  margin-bottom: 40px;

  &__content {
    @media(max-width: $screen-xs-max) {
      width: 100%;
    }
  }

  &__title {
    margin: 0;

    @include word-break;
  }

  &__status {
    color: $light-gray-color;

    &_light {
      color: #c6c6cc;
    }
  }

  &__avatar {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin: 5px 15px 0 0;
    background-color: #e6a873;
  }

  &__btn {
    margin: 0 12px 16px 0;
  }
}
