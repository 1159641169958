@import '~@setapp/ui-kit/styles/mixins';

.change-plan {
  &-form {
    @include vertical-center-content;

    &__item {
      margin-bottom: 10px;
    }

    &__button {
      margin-top: 25px;
    }
  }

  &__note {
    font-size: 11px;
    color: $light-gray-color;
    margin-top: 17px;
  }
}
