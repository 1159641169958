@import '~@setapp/ui-kit/styles/variables';

.resend-email-captcha-modal {
  &__captcha-container {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;

    @media (max-width: $screen-xs-max) {
      margin-bottom: 10px;
      text-align: left;
    }
  }

  &__captcha {
    display: inline-block;
    transform-origin: top left;

    @media (max-width: $screen-xs-max) {
      transform: scale(0.786);
    }
  }
}
