@import '~@setapp/ui-kit/styles/variables';

.payment-details {
  margin-top: 40px;
  width: 400px;
  max-width: 100%;

  @media (min-width: $screen-sm-min) {
    display: inline-block;
  }

  @media (min-width: $screen-lg-min) {
    margin-top: 0;
  }

  &__content {
    min-height: 240px;
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 16px;
  }

  &__buttons {
    margin-top: 40px;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  &__warning-message {
    margin-top: 20px;
    font-size: $font-size-sm;
    color: $red;
  }
}
