@import '~@setapp/ui-kit/styles/variables';

.activate-page-promo {
  height: 100%;
  position: relative;
  background: #f8f8f4;
  border-radius: 20px;
  color: $dark-grey;
  text-align: center;
  flex-grow: 1;
  width: 100%;
  display: flex;
  padding: 40px 20px;

  &__container {
    margin: auto;
  }

  &__image {
    margin-bottom: 20px;
    height: 45vh;
    max-height: 370px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__features {
    max-width: 640px;
    margin: 32px auto 0;
    display: flex;
  }

  &__feature {
    flex: 1 0 0;

    &-icon {
      margin-bottom: 12px;
    }

    &-text {
      padding-left: 28px;
      padding-right: 28px;
      border-right: 1px solid #e5e5e5;
    }

    &:last-child {
      .activate-page-promo__feature-text {
        border-right: none;
      }
    }
  }
}
