@import '~@setapp/ui-kit/styles/variables';

.price-plan-switcher {
  width: 100%;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 16px;
    margin-bottom: 24px;
  }

  &__label {
    padding: 12px 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 2px solid $light-silver;
    border-radius: 15px;
    transition: border 0.3s ease-in-out;

    &:hover {
      border: 2px solid #f6cf98;
    }
  }

  &__input {
    cursor: pointer;
    display: none;

    &:checked + .price-plan-switcher__label {
      background-color: #fefaf4;
      border: 2px solid #f6cf98;
    }
  }

  &__text-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  &__discount {
    color: $sea;
  }
}
