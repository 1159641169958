@import '~@setapp/ui-kit/styles/variables';

.ai-offer {
  width: 100%;

  &__container {
    width: 100%;
    max-width: 1220px;
    padding: 0 20px;
    margin: 0 auto;
  }

  &__inverse-background {
    background: $white;
  }

  &__header {
    color: $white;
    margin: 48px 0 60px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__header-hero {
    margin: 0;
    margin-bottom: 24px;
    font-size: 46px;
    font-weight: 700;
    line-height: 1.13;
    letter-spacing: 1px;
    max-width: 520px;
  }

  &__header-description {
    font-size: 18px;
    line-height: 1.78;
    letter-spacing: normal;
  }

  &__grad-text {
    background-clip: text;
    background-image: linear-gradient(225deg, #7885fa 20%, #dcadda 60%, #ffbfb1);
    color: transparent;
  }

  &__plan-chooser {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;
    color: $white;
  }

  &__chooser-title {
    margin: 0;
  }

  &__chooser-text {
    color: $white;
  }

  &__plans-row {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    width: 100%;
    padding-bottom: 96px;
    gap: 20px;

    @media(max-width: $screen-md-max) {
      flex-direction: column-reverse;
    }
  }

  &__plans-comparison {
    padding-top: 80px;
  }

  &__faq {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  &__loader {
    min-height: 460px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
