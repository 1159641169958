@import '~@setapp/ui-kit/styles/variables';

.switch-to-family-plan {
  &__panel {
    text-align: center;
    margin-top: 32px;
    max-width: 400px;
  }

  &__illustration {
    margin: 0 auto;
  }

  &__activation-message {
    margin-top: 80px;
  }

  &__faq {
    margin-top: 60px;
  }
}
