@import '~@setapp/ui-kit/styles/variables';

.ios-pack-card {
  display: flex;
  margin-bottom: 20px;

  &__info {
    height: fit-content;
    margin: auto 0;
    width: 100vw;
    min-width: 0;

    @media (min-width: $screen-sm-min) {
      width: 100%;
    }
  }

  &__description {
    font-weight: 500;
    margin-bottom: 4px;
    width: 100%;
  }

  &__btn {
    flex-shrink: 0;

    @media (min-width: $screen-md-min) {
      display: none;
    }
  }

  &__name {
    color: $dark-grey;
    margin-bottom: 0;
    opacity: 0.5;
    mask-image: linear-gradient(90deg, #000 85%, transparent);
    overflow: hidden;
    white-space: nowrap;
    flex-grow: 1;
    margin-right: 12px;
    font-weight: 500;
  }

  &__action-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__icon-container {
    width: 96px;
    height: 96px;
    margin-right: 15px;
    padding: 8px;
    cursor: pointer;

    transform: scale(1);
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__icon {
    border-radius: 22px;
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      border-radius: 22px;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
    }
  }
}
