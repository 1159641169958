.info-button {
  display: inline-block;
  font-size: 0;
  padding: 0;
  border: none;
  vertical-align: middle;
  position: relative;
  top: -2px;

  &__tooltip {
    max-width: 260px;
  }
}
