@import '~@setapp/ui-kit/styles/variables';

.braintree-verified {
  &-link {
    display: inline-block;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  &-text {
    margin-left: 8px;
    font-size: 11px;
    color: $light-gray-color;
    letter-spacing: 0.83px;
  }
}
