@import '~@setapp/ui-kit/styles/variables';
@import '~@setapp/ui-kit/styles/mixins';

.info-radio-button {
  &__label {
    display: block;

    .info-radio-button__input:focus + & {
      @include tab-focus;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
