@import '~@setapp/ui-kit/styles/variables';

.price-plan-item {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  color: $text-color;
  box-shadow: inset 0 0 0 1px $btn-default-border;
  border-radius: $border-radius-large;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: inset 0 0 0 1px darken($btn-default-border, 10%);
    color: darken($btn-default-color, 10%);
  }

  &_active {
    box-shadow: inset 0 0 0 1px $btn-default-color;
    background-color: #f5f5f5;

    &:hover {
      box-shadow: inset 0 0 0 1px $btn-default-color;
    }

    .price-plan-item__description {
      display: block;
    }
  }

  &__title {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    margin: 0;
  }

  &__subtitle {
    display: block;
    font-size: 12px;
    line-height: 1.67;
    margin: 0;
  }

  &__description {
    max-width: 190px;
    margin-top: 15px;
    display: none;
  }

  &__price {
    font-size: 28px;
    padding-left: 10px;
    display: block;
    line-height: 1;

    @media(max-width: $screen-xs-max) {
      font-size: 24px;
      display: inline-block;
      padding-left: 0;
    }
  }

  &__price-description {
    display: block;
    text-align: right;
    padding-right: 2px;

    @media(max-width: $screen-xs-max) {
      display: inline-block;
      padding-right: 0;
      padding-left: 2px;
    }
  }

  &__text-container {
    @media(max-width: $screen-xs-max) {
      padding-top: 40px;
    }
  }

  &__price-container {
    @media(max-width: $screen-xs-max) {
      position: absolute;
      top: 20px;
    }
  }
}
