@import '~@setapp/ui-kit/styles/variables';

.signup-ccr-flow {
  margin: auto 0;
  padding-bottom: 60px;
  width: 100%;
  max-width: 480px;

  &__hidden-item {
    display: none;
  }
}
