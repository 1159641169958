@import '~@setapp/ui-kit/styles/variables';

.payment-price-plan {
  all: unset;
  padding: 20px;
  border: 2px solid $very-light-pink;
  border-radius: 15px;

  &:focus {
    outline: revert;
  }

  & + & {
    margin-top: 20px;
  }

  &_checked {
    border-color: #f6cf98;
    background-color: #f6cf981a;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__tag {
    height: 28px;
    padding: 4px 8px;
    // TODO: add $beige-tan to UI Kit
    background-color: #f6cf98;
    color: $dark-grey;
    border: none;
    border-radius: 6px;
    font-weight: $font-weight-medium;
    font-size: $font-size-sm;
    letter-spacing: normal;
    user-select: none;
  }

  &__features {
    margin: 0;
    padding-left: 0;
    list-style: none;
  }

  &__item {
    position: relative;
    padding-left: 28px;

    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      width: 16px;
      height: 16px;
      background-image: url('images/red-cross.svg');
    }

    &_checked {
      &::before {
        background-image: url('images/green-checkmark.svg');
      }
    }
  }
}
