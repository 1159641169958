@import '~@setapp/ui-kit/styles/variables';

.manage-family-page {
  &__hero {
    padding-bottom: 8px;
    border-bottom: 1px solid $very-light-pink;
  }

  &__invites {
    margin-top: 48px;

    &-empty {
      display: flex;
      align-items: center;
    }
  }
}
