@import '~@setapp/ui-kit/styles/variables';

.notification-cancel-seats-link {
  color: $dark-grey;
  text-decoration: underline;
  margin-right: 24px;

  &:hover, &:focus {
    color: $dark-grey;
  }
}
