@import '~@setapp/ui-kit/styles/variables';

.activated-family-plan {
  &__section-title {
    margin-bottom: 15px;
  }

  &__benefits {
    @media (max-width: $screen-md-max) {
      margin-top: 50px;
    }
  }

  &__form-container {
    @media (min-width: $screen-md-min) {
      max-width: 500px;
    }
    margin-bottom: 40px;
  }
}
