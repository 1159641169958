@import '~@setapp/ui-kit/styles/variables';

.payment-failed-notification {
  &__container {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    @media (max-width: $screen-xs-max) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__action {
    margin-left: 30px;
    flex: none;

    @media (max-width: $screen-xs-max) {
      margin-left: 0;
      margin-top: 12px;
    }
  }

  &__help {
    display: flex;
    align-items: center;

    @media (max-width: $screen-xs-max) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.notification-update-details-link {
  color: $dark-grey;
  text-decoration: underline;
  margin-right: 24px;

  &:hover, &:focus {
    color: $dark-grey;
  }
}
