@import '~@setapp/ui-kit/styles/variables';

.confirm-price-plan-step {
  &__proration-amount {
    margin-top: 24px;
    border-top: 1px solid $very-light-pink;
    padding-top: 24px;
    text-align: right;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;

    &-tax-info {
      color: $heather;
      font-size: $font-size-xs;
      font-weight: $font-weight-regular;
    }
  }
}
