@import '~@setapp/ui-kit/styles/variables';

.referral-signup-promo {
  height: 100%;
  position: relative;
  background: #f8f8f4;
  border-radius: 20px;
  color: $dark-grey;
  text-align: center;
  flex-grow: 1;
  width: 100%;
  display: flex;
  padding: 40px 20px;

  &__container {
    margin: auto;
  }

  &__image {
    margin-bottom: 20px;
    height: 45vh;
    max-height: 334px;
    min-height: 200px;
  }

  &__title {
    margin-top: 0;
  }

  &__subtitle {
    max-width: 560px;
    margin: 0 auto;
  }

  &__advantages {
    padding: 24px 32px;
    border-radius: 20px;
    background-color: $alert-warning-bg;
    max-width: 575px;
    margin: 24px auto;
  }

  &__illustration {
    position: relative;
  }

  &__label {
    position: absolute;
    right: 40px;
    top: -6px;
    display: flex;
    align-items: center;
    max-width: 400px;
    padding: 7px 18px 8px 20px;
    background-color: $white;
    border-radius: 12px;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
  }
}
