.auto-download {
  &-section {
    min-width: 298px;
    position: fixed;
    z-index: 1040;
    border-radius: 6px;

    &-firefox {
      top: 20px;
      right: 90px;
    }

    &-safari {
      top: 20px;
      right: 78px;
    }

    &-chrome {
      top: 20px;
      right: 112px;

      &-old-version {
        bottom: 20px;
        left: 20px;
      }
    }
  }

  &-content {
    display: flex;
    align-items: center;

    &__text {
      margin: 0;
      flex-shrink: 0;
    }
  }

  &-icon {
    margin-right: 16px;
    width: 40px;
    height: 40px;
  }
}
