@import '~@setapp/ui-kit/styles/variables';

.embed-page {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 320px;
  height: 100%;
  padding: 0 20px;
  overflow: clip scroll;

  &__notifications {
    position: fixed;
    top: 0;
    right: 0;
    margin: 20px;
    z-index: 1001;

    @media (min-width: $screen-lg-min) {
      top: 20px;
      right: 20px;
    }
  }
}
