@import '~@setapp/ui-kit/styles/variables';

.family-invite-form {
  &__container {
    display: flex;
    flex-direction: column;

    @media (min-width: $screen-sm-min) {
      flex-direction: row;
    }
  }

  &__input {
    flex-grow: 1;
  }

  &__action {
    min-width: 180px;

    @media (min-width: $screen-sm-min) {
      margin-left: 20px;
    }
  }
}
