@import '~@setapp/ui-kit/styles/variables';

.custom-tabs {
  &-navigation {
    border-bottom: solid 1px $very-light-pink;

    > .nav-item {
      transition: color 0.3s, box-shadow 0.3s;
      padding-bottom: 10px;

      &.active {
        box-shadow: inset 0 -2px 0 0 $dark-grey;
      }
    }
  }
}
