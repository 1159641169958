@import '~@setapp/ui-kit/styles/variables';

.ios-collection-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 64px;
  padding: 40px 16px 36px;
  border: solid 1px #cdcccc;

  &::after {
    content: '';
    position: absolute;
    bottom: -38px;
    width: 100%;
    border-bottom: 1px solid #ebebec;
  }

  &, &:hover {
    text-decoration: none;
    color: initial;
  }

  &__apps {
    display: flex;
    width: 100%;
    max-width: 300px;
    justify-content: space-around;
  }

  &__icon {
    width: 44px;
    height: 44px;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    transform: scale(1);
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.1);
    }

    &::after {
      content: '';
      border-radius: 10px;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
    }
  }

  &__description {
    text-align: center;
    max-width: 330px;
    opacity: 0.5;
    color: $dark-grey;
    font-weight: 500;
  }
}
