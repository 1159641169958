@import '~@setapp/ui-kit/styles/variables';

.offer-benefits {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 0 0 50px;
  color: $white;
  list-style: none;
  margin-top: -16px; // to shorten distance to plans row

  &_with-border {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 60px;
    margin-top: 0;
  }

  @media (max-width: $screen-md-max) {
    flex-direction: column;
    gap: 16px;
  }

  &__item {
    display: flex;
    gap: 16px;
    align-items: flex-start;
  }

  &__text {
    font-size: 16px;
    max-width: 260px;

    @media (max-width: $screen-md-max) {
      max-width: 100%;
    }
  }
}
