@import '~@setapp/ui-kit/styles/variables';

.sidebar-nav__header {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  height: $sidebar-nav-mobile-height;
  z-index: 10;

  @media (min-width: $screen-sm-min) {
    display: none;
  }
}

.sidebar-nav__toggle {
  margin: 0;
  font-size: 18px;
  width: 100%;
  cursor: pointer;
  border: none;
  text-align: left;
  padding: 10px 60px 10px 20px;
  background: #f5f5f5;
  border-radius: $border-radius-base;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: 20px;
    width: 20px;
    top: 50%;
    right: 16px;
    margin-top: -10px;
    background: url('images/toggle-arrow.svg') no-repeat center;
  }

  &_expanded {
    border-radius: $border-radius-base $border-radius-base 0 0;

    &::before {
      transform: rotate(180deg);
    }
  }

  @media(min-width: $screen-sm-min) {
    display: none;
  }
}
