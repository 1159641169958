@import '~@setapp/ui-kit/styles/variables';

.successful-registration-default {
  // TODO: add this color to the UI kit
  $blue-grey: #6f9ba9;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;

  @media (min-width: $screen-md-min) {
    padding: 0 28px;
  }

  &__content {
    width: 100%;
    flex-shrink: 0;

    @media (min-width: $screen-md-min) {
      margin: 0 50px 36px 0;
      width: 480px;
    }
  }

  &__icon {
    margin-left: -12px;
  }

  &__download-hint {
    font-size: $font-size-md;
    color: $heather;
    display: block;
    margin-top: 48px;
  }

  &__illustration {
    display: none;

    @media (min-width: $screen-md-min) {
      display: block;
      width: 100%;
    }
  }

  &__launch-button {
    min-width: 240px;
  }

  &__invite-participants-button {
    min-width: 240px;
  }
}
