@import '~@setapp/ui-kit/styles/variables';

.ios-apps-pack {
  margin-top: 32px;
  padding-bottom: 12px;
  border-bottom: 1px solid #ebebec;

  &__container {
    margin: 0 -20px;
    display: flex;
    width: 100vw;
    overflow: scroll;
    scroll-snap-type: x mandatory;

    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: $screen-sm-min) {
      width: 100%;
      flex-wrap: wrap;
    }
  }

  &__link, &__link:hover {
    text-decoration: none;
    color: initial;
  }

  &__title {
    margin: 0 0 20px;
  }

  &__column {
    scroll-snap-align: start;
    padding-left: 20px;
    width: 90vw;

    & + & {
      width: 100vw;
      padding-right: 20px;
    }

    @media (min-width: $screen-sm-min) {
      width: 100%;

      & + & {
        width: 100%;
        padding-right: 0;
      }
    }

    @media (min-width: $screen-md-min) {
      &, & + & {
        width: 50%;
      }
    }
  }

  &__illustration {
    width: 100%;
    height: 45vw;
    border-radius: 16px;
    margin-bottom: 32px;
    background-image: url('../images/starter-pack.png');
    background-color: #dbeaea;
    background-repeat: no-repeat;
    background-size: 80% auto;
    background-position: top -20px center;

    &_stress {
      background-image: url('../images/stress-less.png');
      background-color: #e6c3a5;
    }
  }
}
