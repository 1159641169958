@import '~@setapp/ui-kit/styles/variables';

.device-list-item {
  padding: 10px 20px 10px 64px;
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 -20px;
  border-radius: 6px;

  &:hover {
    background-color: $light-silver;
  }

  @media (max-width: $screen-sm-max) {
    margin: 0;
    padding: 10px 0 10px 44px;
    align-items: flex-start;

    &:hover {
      background-color: initial;
    }
  }

  @media (max-width: $screen-xs-max) {
    flex-direction: column;
  }

  &__icon {
    position: absolute;
    top: 6px;
    left: 20px;

    @media (max-width: $screen-sm-max) {
      left: 0;
    }
  }

  &__name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    @media (max-width: $screen-sm-max) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media (max-width: $screen-xs-max) {
      margin-bottom: 12px;
    }
  }
}
