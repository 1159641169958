@import '~@setapp/ui-kit/styles/variables';

.how-it-works-video-modal {
  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 84px 70px;

    @media(max-width: $screen-xs-max) {
      padding: 0;
    }
  }

  &__video {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px;
    border-radius: 20px;
    height: 100%;
    background-color: #000;
  }

  &__iframe {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
