@import '~@setapp/ui-kit/styles/variables';

.successful-registration-desktop-ccr {
  display: flex;
  width: 100%;
  justify-content: center;

  &__title {
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;

    @media (min-width: $screen-md-min) {
      margin-bottom: 64px;
    }
  }

  &__steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px 18px;

    @media (min-width: $screen-lg-min) {
      flex-direction: row;
      align-items: initial;
    }
  }

  &__step {
    max-width: 380px;

    @media (min-width: $screen-lg-min) {
      width: 380px;
    }
  }

  &__step-image-container {
    position: relative;
    border-radius: 15px;
    padding: 12px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $off-white;
  }

  &__step-number {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 15px;
    padding: 0 16px;
    font-size: 26px;
    font-weight: 600;
    background-color: white;
  }

  &__step-image {
    max-width: 100%;
    height: auto;
  }

  &__step-title {
    margin-top: 24px;
    margin-bottom: 8px;
    display: block;
    font-size: 18px;
  }

  &__cta-container {
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
  }

  &__button {
    min-width: 200px;
  }
}
