@import '~@setapp/ui-kit/styles/variables';

.choose-price-plan-step {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $screen-md-max) {
      flex-direction: column;
      align-items: start;
    }
  }

  &__price-cards {
    display: flex;
    justify-content: center;
    margin: 20px -6px 0;

    @media (max-width: $screen-md-max) {
      flex-direction: column;
      margin: 20px 0 0;
    }

    &-item {
      width: calc(100% / 3);
      padding: 0 6px;

      @media (max-width: $screen-md-max) {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
      }
    }
  }
}
