@import '~@setapp/ui-kit/styles/variables';

.setapp-mobile-fixed {
  display: flex;
  position: fixed;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #e8e8e8;
  background: $white;
  padding: 12px 20px;
}
