@import '~@setapp/ui-kit/styles/variables';

.setapp-mobile-onboard {
  &__title {
    font-size: 32px;
    font-weight: $font-weight-bold;
    line-height: 40px;
    margin-bottom: 20px;
    margin-top: 6px;
    padding-right: 6px;
  }

  &__item {
    border: 1px solid $very-light-pink;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    & + & {
      margin-top: 12px;
    }
  }

  &__item-title {
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    margin-bottom: 0;
  }

  &__item-description {
    font-size: $font-size-sm;
    margin-bottom: 0;
  }

  &__faq-title {
    margin-top: 60px;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
  }

  &__feature-list {
    padding: 0;
    margin: 0;
  }

  &__list-item {
    list-style: none;
    padding-left: 28px;
    font-size: $font-size-sm;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      background-image: url('../../images/bullet.svg');
      content: '';
      display: inline-block;
      background-size: contain;
      height: 12px;
      width: 12px;
      margin-left: -28px;
      margin-right: 12px;
      position: relative;
      top: 2px;
    }
  }

  &__knowledge {
    margin-left: 48px;
  }

  &__image {
    margin: 20px auto -20px;
    max-width: 100%;
    width: auto;
  }
}
