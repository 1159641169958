@import '~@setapp/ui-kit/styles/variables';

.app-header {
  /** Navigation **/
  $navigation-font-size: 20px;
  $navigation-line-height: 1.84;
  $navigation-font-size-tablet: 26px;
  $navigation-font-size-desktop: 14px;
  $navigation-padding-y: 16px;
  $navigation-list-padding-y: 2px;
  $navigation-link-padding-y: 5px;
  $navigation-link-padding-x: 16px;
  $navigation-link-padding-x-tablet: 24px;
  $navigation-link-padding-x-desktop: 0;
  $navigation-height: floor($navigation-font-size-desktop * $navigation-line-height)
  + ($navigation-padding-y + $navigation-list-padding-y + $navigation-link-padding-y) * 2;
  $layout-content-padding-x: 20px;
  $white-smoke: #f2f2f2;

  width: 100%;
  padding-top: $navigation-padding-y;
  padding-bottom: $navigation-padding-y;
  background-color: $white;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background: rgba(38, 38, 43, 0.4);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    z-index: 0;

    @media(min-width: $screen-lg-min) {
      position: initial;
      height: initial;
      margin: 0 0 0 auto;
      visibility: hidden;
      opacity: 0;
    }

    &_shown {
      visibility: visible;
      opacity: 1;

      @media(min-width: $screen-lg-min) {
        animation: none;
        background: none;
      }
    }
  }

  &__content {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding-left: $layout-content-padding-x;
    padding-right: $layout-content-padding-x;

    @media(min-width: $screen-md-min) {
      padding-left: $layout-content-padding-x * 2;
      padding-right: $layout-content-padding-x * 2;
    }
  }

  &__action-button {
    margin: 0 20px;

    @media (min-width: $screen-sm-min) {
      // Makes flex children stick to the right
      margin-left: auto;
    }
  }

  &__logo {
    font-size: 0;
  }

  &__open-button {
    $button-height: 20px;
    border: none;
    cursor: pointer;
    height: $button-height;
    // add some margin to take the same place vertically as menu list height
    margin: ($navigation-height - $navigation-padding-y * 2 - $button-height) * 0.5 0;
    padding: 0;
    background: none;
    color: $dark-grey;
    fill: currentColor;
    transition: color 0.3s ease-in-out;
    display: flex;
  }

  &__close-button {
    position: absolute;
    right: 20px;
    top: 26px;
    padding: 0;
    cursor: pointer;
    background: none;
    border: none;
    color: $dark-grey;
    fill: currentColor;
    transition: color 0.3s ease-in-out;
    z-index: 5;

    @media(min-width: $screen-md-min) {
      right: 40px;
    }
  }

  &__menu-slider {
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    transform: translateX(100%);
    background: $white;
    z-index: 10;

    @media(min-width: $screen-sm-min) {
      width: initial;
    }

    @media(min-width: $screen-lg-min) {
      height: initial;
      position: initial;
      transform: translateX(0);
      background: none;
    }

    &_enter,
    &_exit {
      transition: transform 0.3s ease-in-out;
    }

    &_enter-active,
    &_enter-done {
      transform: translateX(0);
    }
  }

  &__menu-logo_mobile {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  &__menu-logo_tablet {
    position: absolute;
    top: 130px;
    left: 90px;
  }

  &__menu-list-container {
    position: relative;
    height: 100%;
  }

  &__menu-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    margin: 0;
    background-size: 19px 30px;
    padding: 82px 20px 0;

    @media(min-width: $screen-sm-min) {
      background-size: 51px 80px;
      padding: 245px 64px 20px;
    }

    @media(min-width: $screen-lg-min) {
      position: initial;
      flex-direction: row;
      flex-shrink: 0;
      height: initial;
      padding: $navigation-list-padding-y 0;
    }
  }

  &__menu-list-item {
    flex-shrink: 0;

    @media(min-width: $screen-lg-min) {
      & + & {
        margin-left: 30px;
      }
    }
  }

  &__menu-list-item_divider::before {
    @media(min-width: $screen-lg-min) {
      margin-right: 30px;
      border-left: 1px solid $heather;
      height: 20px;
      content: '';
    }
  }

  &__link {
    display: block;
    min-width: 280px;
    padding: $navigation-link-padding-y $navigation-link-padding-x;
    margin: 4px 0;
    color: $dark-grey;
    font-size: $navigation-font-size;
    line-height: $navigation-line-height;
    font-weight: $font-weight-bold;
    letter-spacing: 0.9px;
    text-decoration: none;
    transition: box-shadow 0.15s ease-in-out,
    color 0.3s ease-in-out;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: inherit;
    }

    &_active {
      border-radius: 6px;
      background: $white-smoke;
    }

    @media(min-width: $screen-sm-min) {
      padding: $navigation-link-padding-y $navigation-link-padding-x-tablet;
      min-width: 280px;
      font-size: $navigation-font-size-tablet;
    }

    @media(min-width: $screen-lg-min) {
      display: inline;
      min-width: initial;
      padding: $navigation-link-padding-y $navigation-link-padding-x-desktop;
      margin: 0;
      border-radius: 0;
      font-size: $navigation-font-size-desktop;
      font-weight: $font-weight-medium;

      &:hover,
      &:focus,
      &:active {
        box-shadow: inset 0 -2px 0 0 currentColor;
      }

      &_active {
        box-shadow: inset 0 -2px 0 0 currentColor;
        background: none;
      }
    }
  }
}
