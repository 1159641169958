@import '~@setapp/ui-kit/styles/variables';

.page-title {
  margin-top: 0;
  margin-bottom: 20px;

  @media(min-width: $screen-md-min) {
    margin-bottom: 32px;
  }
}
