@import '~@setapp/ui-kit/styles/variables';

.special-offer-details {
  &__item {
    margin-bottom: 8px;
    font-size: $font-size-lg;

    &-period {
      float: right;
    }
  }

  &__price {
    margin-top: 20px;
    border-top: 1px solid $very-light-pink;
    padding-top: 24px;
  }
}
