@import '~@setapp/ui-kit/styles/variables';

.text-delimiter {
  display: flex;
  align-items: center;
  padding: 19px 0;

  &::before,
  &::after {
    content: '';
    flex: 1;
    background-color: $hr-border;
    height: 1px;
  }

  &-text {
    padding: 0 14px;
    color: #4a4a4a;
  }
}
