@import '~@setapp/ui-kit/styles/variables';

.emergency-banner {
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding: 32px 16px;
  justify-content: space-between;
  background-color: #f4f0e4;

  @media(min-width: $screen-md-min) {
    padding-left: 40px;
    padding-right: 40px;
  }

  &__text {
    position: relative;
    padding-left: 30px;
    align-self: center;
    font-size: 18px;
  }

  &__icon {
    position: absolute;
    left: 0;
    top: 2px;
  }

  &__close {
    width: 32px;
    height: 32px;
  }
}
