.fade-transition {
  $transitionDuration: 200ms;

  &_enter {
    opacity: 0;
  }

  &_enter-active {
    opacity: 1;
    transition: opacity $transitionDuration ease-in;
  }

  &_exit {
    opacity: 1;
  }

  &_exit-active {
    opacity: 0;
    transition: opacity $transitionDuration ease-in;
  }
}
