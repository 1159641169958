@import '~@setapp/ui-kit/styles/variables';

.special-offer-banner {
  width: 100%;
  position: relative;

  @media(min-width: $screen-sm-min) {
    padding-top: 12px;
  }

  @media(max-width: $screen-sm-min) {
    margin-bottom: 40px;
  }

  &__content {
    height: 160px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background: #1f1f1f;

    &:hover {
      text-decoration: none;
    }

    @media(min-width: $screen-sm-min) {
      height: 72px;
    }
  }

  &__title {
    align-self: start;
    margin: 24px 32px 0;
    max-width: 320px;
    text-align: center;
    color: $white;
    font-weight: $font-weight-medium;

    &_underlined {
      text-decoration: underline;
    }

    @media(min-width: $screen-sm-min) {
      align-self: unset;
      margin: 0 28px;
      max-width: 416px;
    }

    @media(min-width: $screen-lg-min) {
      margin: 0 36px;
      max-width: 530px;
      font-size: $font-size-lg;
    }
  }

  &__partners-icon {
    position: absolute;
    top: 96px;
    left: 10%;
    display: none;

    @media(min-width: $screen-sm-min) {
      display: block;
      left: 25%;
      position: initial;
      top: initial;
      margin-top: 12px;
    }

    &_small {
      position: absolute;
      left: 50%;
      margin-left: -123px;
      top: 90px;

      @media(min-width: $screen-sm-min) {
        display: none;
      }
    }
  }

  &__setapp-icon {
    position: absolute;
    top: 96px;
    right: 10%;
    display: none;

    @media(min-width: $screen-sm-min) {
      display: block;
      right: 25%;
      position: initial;
      top: initial;
      left: initial;
      margin-top: 8px;
    }
  }
}
