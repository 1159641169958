.restrict-setapp-mobile {
  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0.5px;
  }

  &__description {
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.5px;
  }
}
