@import '~@setapp/ui-kit/styles/variables';

.fullscreen-layout {
  position: relative;
  height: 100%;
  min-width: 320px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;

  &_dark {
    background-color: $dark-grey;
  }

  &__notifications {
    position: fixed;
    top: 0;
    right: 0;
    margin: 20px;
    z-index: 1001;

    @media (min-width: $screen-lg-min) {
      top: 20px;
      right: 20px;
    }
  }

  &__container {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    width: 100%;
  }

  &__primary-content {
    display: flex;
    flex-direction: column;
    flex: 0 1 100%;

    @media(min-width: $screen-lg-min) {
      flex: 1 0 calc(100% * (5 / 12));
    }
  }

  &__logo-container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 60px 40px 0;

    @media (max-width: $screen-sm-max) {
      max-width: 520px;
      padding-left: 20px;
      padding-right: 20px;
    }

    @media (max-width: $screen-xs-max) {
      padding-top: 20px;
    }
  }

  &__logo {
    padding-left: 92px;
    padding-right: 92px;

    @media (max-width: $screen-sm-max) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__primary-container {
    position: relative;
    width: 100%;
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    flex: 1 0 100%;

    @media(min-width: $screen-lg-min) {
      max-width: 420px;
    }

    &_with-logo {
      padding-top: 80px;
      padding-bottom: 80px;

      @media(min-width: $screen-sm-min) {
        padding-top: 120px;
        // the bottom padding is bigger to shift the content a bit higher than the geometrical center
        padding-bottom: 135px;
      }
    }

    &_wide {
      max-width: 520px;
    }
  }

  &__setapp-logo {
    position: absolute;
    top: 20px;
    left: 20px;

    @media(min-width: $screen-sm-min) {
      top: 60px;
    }
  }

  &__secondary-content {
    display: none;

    @media(min-width: $screen-lg-min) {
      flex: 1 0 calc(100% * (7 / 12));
      display: block;
    }
  }

  &__secondary-container {
    flex: 1 0 100%;
  }

  &__sticky {
    display: flex;
    position: sticky;
    top: 0;
    min-height: 100vh;
    padding: 20px;
  }

  &__header {
    background-color: $white;
    z-index: 30;
    width: 100%;
  }
}
