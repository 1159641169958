.trial-unknown-action {
  margin-top: 40px;

  &__description {
    margin-bottom: 32px;
    max-width: 445px;
  }

  &__cta-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__btn {
    &-download {
      min-width: 240px;
    }

    &-buy-plan {
      min-width: 160px;
    }
  }
}
