@import '~@setapp/ui-kit/styles/variables';

.member-invitation-form {
  display: flex;
  flex-direction: column;

  @media (min-width: $screen-md-min) {
    align-items: flex-start;
    flex-direction: row;
  }

  &__submit {
    flex: none;
  }

  .form-group {
    flex-grow: 1;
    margin: 0 0 12px;

    @media (min-width: $screen-md-min) {
      margin: 0 12px 0 0;
    }
  }
}
